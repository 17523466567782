import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { fetchModifiers, clearModifierGroups, setQueryDetails } from '../../../../redux/modifierSetsSlice';

import { useAppContext } from '../../../../context/context';

import useModifierGroupManager from './hooks/useModifierGroupManager';

import PageTitle from '../../../../components/utilities/page-title/page-title';
import Button from '../../../../components/utilities/button/button';
import Search from '../../../../components/utilities/search/search';

import ModifierGroupsCards from './components/modifier-groups-cards';
import ModifierCardsSection from './components/modifier-card-section';
import EditModifierGroupModal from './modals/edit-modifier-group-modal';

import { ReactComponent as NoData } from '../../../../assets/icons/no_modifier_groups.svg';

import styles from './modifiers.module.scss';

function Modifiers() {
  const dispatch = useDispatch();
  const { token } = useAppContext();
  const [searchParams, setSearchParams] = useSearchParams();

  const queryDetails = useSelector((state) => state.modifierSets.queryDetails);
  const modifierGroupsStatus = useSelector((state) => state.modifierSets.status);
  const modifierGroups = useSelector((state) => state.modifierSets.modifierSets);
  const currentModifierGroup = useSelector((state) => state.modifierSets.currentmodifierGroup);

  const isLoading = modifierGroupsStatus === 'loading' || modifierGroupsStatus === 'idle';

  const {
    modifierGroup,
    setModifierGroup,
    isModalOpen,
    isEditMode,
    isCurrentModifierGroupLoading,
    handleSaveModifierGroup,
    handleDeleteModifierGroup,
    handleShowAddModal,
    handleShowEditModal,
    handleModalClose,
    errors,
  } = useModifierGroupManager({
    modifierGroups,
    currentModifierGroup,
    status: isLoading,
    urlParams: { searchParams, setSearchParams },
  });

  useEffect(() => {
    if (token) {
      dispatch(fetchModifiers({ token, modifierDetails: queryDetails }));
    }

    return () => dispatch(clearModifierGroups());
  }, [dispatch, queryDetails, token]);

  const handleSetResult = (result) => {
    dispatch(setQueryDetails({ search: result }));
  };

  const handleSearchClean = () => {
    dispatch(setQueryDetails({ search: '' }));
  };

  return (
    <div className={styles.main_container}>
      <div className={styles.head_container}>
        <div className={styles.title_and_button}>
          <PageTitle name={'Modifiers'} />
          <div>
            <Button title={'Add Modifier Group'} onClick={handleShowAddModal} />
          </div>
        </div>
        <div className={styles.cards_controller}>
          <div className={styles.search}>
            <Search
              placeholder="Search Modifier by Set Name"
              onSearchResults={handleSetResult}
              onClear={handleSearchClean}
              autoFilter={false}
            />
          </div>
        </div>
      </div>
      <div className={styles.cards_container}>
        <ModifierCardsSection
          isLoading={isLoading}
          length={modifierGroups.length}
          noItemsProps={{
            title: 'No modifier Groups added.',
            description: 'Click on the «+ Add Modifier group» button to create the first one.',
          }}
          icon={NoData}
        >
          <ModifierGroupsCards
            modifiers={modifierGroups}
            onDelete={handleDeleteModifierGroup}
            onEdit={handleShowEditModal}
          />
        </ModifierCardsSection>
      </div>
      {isModalOpen && (
        <EditModifierGroupModal
          modifierGroup={modifierGroup}
          setModifierGroup={setModifierGroup}
          saveChanges={handleSaveModifierGroup}
          isModalOpen={isModalOpen}
          isEditMode={isEditMode}
          isLoading={isCurrentModifierGroupLoading}
          closeModal={handleModalClose}
          errors={errors}
        />
      )}
    </div>
  );
}

export default Modifiers;
