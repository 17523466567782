import styles from './toggler.module.scss';
import clsx from 'clsx';

const Toggler = ({ toggle, onToggle, titles = { active: 'Active', inactive: 'Inactive' }, toggleWidth = 152 }) => {
  const handleOnToggle = (value) => {
    onToggle(value);
  };

  return (
    <div className={styles.container} style={{ '--toggler-width': `${toggleWidth}px` }}>
      <div className={clsx(styles.slider, { [styles.active]: toggle })}></div>
      <span onClick={() => handleOnToggle(true)}>{titles.active}</span>
      <span onClick={() => handleOnToggle(false)}>{titles.inactive}</span>
    </div>
  );
};

export default Toggler;
