import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { fetchWastages } from '../../../../redux/wastageReasonsSlice';

import { useAppContext } from '../../../../context/context';
import usePagination from '../../business/order-types/usePagination';

import PageTitle from '../../../../components/utilities/page-title/page-title';
import Pagination from '../../../../components/utilities/pagination/pagination';
import Button from '../../../../components/utilities/button/button';

import WastageReasonList from './wastage-reasons-list';
import WastageForm from './forms/wastage-form';
import useWastageReasonsManagement from './hooks/useWastageReasonsManagement';

import { compareArraysOfObjectsById } from '../../../utilities/utils';

import styles from './wastage-reasons.module.scss';

const PAGE_SIZE_OPTIONS = [
  { label: 5, value: 5 },
  { label: 6, value: 6 },
  { label: 7, value: 7 },
  { label: 8, value: 8 },
  { label: 10, value: 10 },
  { label: 15, value: 15 },
  { label: 20, value: 20 },
  { label: 25, value: 25 },
];

const DEFAULT_PAGINATION = {
  currentPage: 1,
  itemsPerPage: 5,
};

function WastageReasons() {
  const wastageReasonStatus = useSelector((state) => state.wastageReason.status);
  const wastageReason = useSelector((state) => state.wastageReason.wastageReason);

  const isLoading = wastageReasonStatus === 'loading' || wastageReasonStatus === 'idle';

  const dispatch = useDispatch();
  const { token } = useAppContext();

  const {
    currentPageItems,
    page,
    perPage,
    isPrevActive,
    isNextActive,
    handleSelectPageCount,
    handleClickPrev,
    handleClickNext,
  } = usePagination(wastageReason, DEFAULT_PAGINATION.currentPage, DEFAULT_PAGINATION.itemsPerPage);

  const {
    wastageState,
    initialWastageState,
    handleAddReason,
    handleToggleWastageActive,
    handleconfirmAndDeleteReason,
    handleUpdateReasonName,
    handleChangeList,
    handleSaveChanges,
    handleDragWastage,
  } = useWastageReasonsManagement(currentPageItems);

  const hasUnsavedChanges = useMemo(
    () => compareArraysOfObjectsById(wastageState, initialWastageState),
    [initialWastageState, wastageState],
  );

  useEffect(() => {
    if (token) {
      dispatch(fetchWastages({ token }));
    }
  }, [dispatch, token]);

  return (
    <div className={styles.main_container}>
      <div className={styles.head_container}>
        <PageTitle name={'Wastage'} />
      </div>
      <div className={styles.content}>
        <WastageForm onAdd={handleAddReason} />
        <WastageReasonList
          wastages={wastageState}
          onInputChange={handleUpdateReasonName}
          onListChange={handleChangeList}
          onDelete={handleconfirmAndDeleteReason}
          onToggleActive={handleToggleWastageActive}
          isLoading={isLoading}
          isFailed={wastageReasonStatus === 'failed'}
          onDrag={handleDragWastage}
        />
        <Pagination
          pageSizeOptions={PAGE_SIZE_OPTIONS}
          numberOfItems={wastageReason.length}
          currentPage={page}
          currentPageSize={perPage}
          onPageSizeChange={handleSelectPageCount}
          inputPaginationControl={{
            isActive: true,
            isPrevActive,
            isNextActive,
          }}
          onPrev={handleClickPrev}
          onNext={handleClickNext}
        />
        {!hasUnsavedChanges && (
          <div className={styles.action_buttons}>
            <Button className={styles.save_button} title={'Update'} onClick={handleSaveChanges} />
          </div>
        )}
      </div>
    </div>
  );
}

export default WastageReasons;
