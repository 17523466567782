import React, { useState, useEffect, useMemo } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { useAppContext } from '../../../../context/context';

import {
  fetchCustomerReceiptSettings,
  updateCustomerReceiptSettings,
  updateState,
} from '../../../../redux/customerReceiptSettingsSlice';
import { fetchBusinessInformation } from '../../../../redux/informationSlice';

import Button from '../../../utilities/button/button';
import PageTitle from '../../../utilities/page-title/page-title';
import CustomerReceiptSettingsForm from './form/customer-receipt-settings-form';
import CustomerReceiptExample from './customer-receipt-settings-example';
import BackdropLoading from '../../../utilities/backdrop-loading/backdrop-loading';

import styles from './customer-receipt-settings.module.scss';

function CustomerReceiptSettings() {
  const dispatch = useDispatch();
  const { token, locationId } = useAppContext();

  const [businessInformation, setBusinessInformation] = useState({});

  const receiptSettingsStatus = useSelector((state) => state.customerReceiptSettings.status);
  const initialReceiptSettings = useSelector((state) => state.customerReceiptSettings.initialCustomerReceiptSettings);
  const receiptSettings = useSelector((state) => state.customerReceiptSettings.customerReceiptSettings);
  const business_information_status = useSelector((state) => state.business_information.status);
  const business_information = useSelector((state) => state.business_information.business_information);

  const loadingState = business_information_status === 'loading' && receiptSettingsStatus === 'loading';

  const hasUnsavedChanges = useMemo(
    () => JSON.stringify(receiptSettings) !== JSON.stringify(initialReceiptSettings),
    [initialReceiptSettings, receiptSettings],
  );

  useEffect(() => {
    if (token && locationId) {
      dispatch(fetchCustomerReceiptSettings({ token }));
      dispatch(fetchBusinessInformation({ token, id: locationId }));
    }
  }, [dispatch, locationId, token]);

  useEffect(() => {
    const flattenBussinessInformation = Object.entries(business_information)
      .filter(([_, value]) => typeof value === 'object')
      .reduce((acc, [_, value]) => ({ ...acc, ...value }), {});

    flattenBussinessInformation && setBusinessInformation(flattenBussinessInformation);
  }, [business_information]);

  const handleFormChange = (id, checked) => {
    const updatedDetails = {
      ...receiptSettings,
      [id]: checked,
    };
    dispatch(updateState(updatedDetails));
  };

  const handleSubmit = () => {
    dispatch(updateCustomerReceiptSettings({ id: receiptSettings.id, updatedDetails: receiptSettings, token }));
  };

  return (
    <div className={styles.main_container}>
      <div className={styles.head_container}>
        <PageTitle name={'Customer Receipt Settings'} />
      </div>

      <div className={styles.main_content}>
        {loadingState ? <BackdropLoading /> : null}
        <div className={styles.form}>
          <CustomerReceiptSettingsForm settings={receiptSettings} onFormChange={handleFormChange} />
          <Button title="Save" onClick={handleSubmit} disabled={!hasUnsavedChanges} style={{ marginBottom: 0 }} />
        </div>
        <CustomerReceiptExample settings={receiptSettings} bussinessInfo={businessInformation} />
      </div>
    </div>
  );
}

export default CustomerReceiptSettings;
