import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { useAppContext } from '../../../../../../context/context';

import { addTax } from '../../../../../../redux/taxesSlice';

import Modal from '../../../../../utilities/modal/modal';
import Button from '../../../../../utilities/button/button';
import Input from '../../../../../utilities/input/input';
import PageTitle from '../../../../../utilities/page-title/page-title';
import Selector from '../../../../../utilities/selector/selector';
import Label from '../../../../../utilities/label/label';
import { convertValueByType } from '../../../../../utilities/utils';

import styles from './add-new-tax.module.scss';

function EditCategoryModal({
  isModalOpen,
  closeModal,
}) {
  // Hooks
  const dispatch = useDispatch();
  const { token, locationId } = useAppContext();

  // Local state
  const [newTax, setNewTax] = useState({
    name: '',
    rate: '',
  });

  const [taxType, setTaxType] = useState('Percentage');

  // Constants
  const taxTypes = [
    { value: 1, label: 'Percentage' },
    { value: 2, label: 'Dollar' },
  ];

  // Handlers
  const handleInputChange = (e, type) => {
    const { name, value } = e.target;

    if (name === 'type') {
      const foundItem = taxTypes.find((item) => item.value === Number(value));
      const label = foundItem ? foundItem.label : '';
      setTaxType(label);
    } else {
      setNewTax((prev) => ({
        ...prev,
        [name]: convertValueByType(value, type),
      }));
    }
  };

  const handleSave = () => {
    const newDetails = {
      is_active: true,
      location_id: Number(locationId),
      name: newTax.name,
      rate: Number(newTax.rate),
      tax_type: taxType === '1' ? 'Percentage' : 'Dollar',
      item_pricing: 0,
      item_ids: []
    };

    dispatch(addTax({ newDetails, token }));
    setNewTax({
      name: '',
      rate: '',
    });
    setTaxType('Percentage');
    closeModal();
  };

  return (
    <Modal isOpen={isModalOpen} onClose={closeModal} className={styles.modal}>
      <section className={styles.container}>
        <div className={styles.header}>
          <PageTitle name={'Tax Category'} className={styles.title} />
          <p className={styles.description}>{'Create a new tax category'}</p>
        </div>
        <div className={styles.form_container}>
          <div className={styles.fields}>
            <div className={styles.field}>
              <Input
                type="text"
                name="name"
                label="Tax name"
                value={newTax.name}
                onChange={handleInputChange}
                required={true}
              />
            </div>
            <div className={styles.field}>
              <Input
                type="text"
                name="rate"
                label="Tax rate"
                value={newTax.rate}
                onChange={handleInputChange}
                required={true}
              />
            </div>

            <div className={styles.field}>
              <Label text="Tax Type" required />
              <Selector
                selectedItem={taxType}
                items={taxTypes}
                onSelectItem={(value) => setTaxType(value)}
                required={true}
              />
            </div>
          </div>

          <div className={styles.action_buttons}>
            <Button
              className={styles.button}
              variant={'secondary'}
              title={'Cancel'}
              onClick={closeModal}
            />
            <Button
              className={styles.button}
              title={'Add tax'}
              onClick={handleSave}
            />
          </div>
        </div>
      </section>
    </Modal>
  );
}

export default EditCategoryModal;
