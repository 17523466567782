import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../../../../context/context';

import { addNewPrinter } from '../../../../redux/printersSlice';
import { fetchOrderTypes } from '../../../../redux/orderTypesSlice';
import { fetchCategories } from '../../../../redux/categoriesSlice';

import PrinterSettingsList from './printer-list/printer-settings-list';

import PageTitle from '../../../utilities/page-title/page-title';
import Input from '../../../utilities/input/input';
import Select from '../../../utilities/selector/selector';
import Button from '../../../utilities/button/button';
import Checkbox from '../../../utilities/checkbox/checkbox';
import BackdropLoading from '../../../utilities/backdrop-loading/backdrop-loading';
import { Subtitle } from '../../../utilities';

import { usePrinterSettings } from './hooks/usePrinterSettings';
import { registrationSchema } from './validation';
import useFormValidation from '../../../utilities/useFormValidation';

import styles from './printers.module.scss';

const printerTypes = [
  { label: 'Customer', value: 'Customer' },
  { label: 'Kitchen', value: 'Kitchen' },
  { label: 'KitchenAndCustomer', value: 'KitchenAndCustomer' },
];

const AddPrinter = () => {
  const { token } = useAppContext();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const printersStatus = useSelector((state) => state.printers.status);
  const orderTypes = useSelector((state) => state.orderTypes.orderTypes);
  const orderTypesStatus = useSelector((state) => state.orderTypes.status);
  const categories = useSelector((state) => state.categories.categories);
  const categoriesStatus = useSelector((state) => state.categories.status);

  const { errors, validate } = useFormValidation(registrationSchema);

  const { printer, printerSettings, handleOrderTypes, handleInputChange, setPrinter } = usePrinterSettings(orderTypes);

  const statusLoading = printersStatus === 'loading';
  const orderTypesLoading = orderTypesStatus === 'loading';
  const categoriesLoading = categoriesStatus === 'loading';

  useEffect(() => {
    if (token && orderTypesStatus === 'idle' && categoriesStatus === 'idle') {
      dispatch(fetchOrderTypes({ token }));
      dispatch(fetchCategories({ token }));
    }
  }, [categoriesStatus, dispatch, orderTypesStatus, token]);

  const handleCategoriesSet = useCallback(
    (id, value) => {
      if (id === 'All' && categories.length) {
        setPrinter((prev) => ({
          ...prev,
          category_ids: value ? categories.map((c) => c.id) : [],
        }));
        return;
      }
      setPrinter((prev) => ({
        ...prev,
        category_ids: value ? [...(prev?.category_ids || []), id] : prev?.category_ids.filter((c) => c !== id),
      }));
    },
    [categories, setPrinter],
  );

  const memoizedOnChange = useCallback(
    (value) => handleInputChange({ target: { value, name: 'receipt_print_type' } }),
    [handleInputChange],
  );

  const handleCancel = () => {
    navigate('/business/printers');
  };

  const handleSaveChanges = async () => {
    if (validate(printer)) {
      try {
        await dispatch(addNewPrinter({ newPrinterDetails: printer, token })).unwrap();
        navigate('/business/printers');
      } catch {}
    }
  };

  return (
    <section className={styles.main_section}>
      <div className={styles.head_container}>
        <PageTitle name={'Create Printer'} />
      </div>
      <div className={styles.form_container}>
        {statusLoading ? <BackdropLoading /> : null}
        <div className={styles.fields}>
          <div className={styles.field}>
            <Input
              label={'Printer Name'}
              value={printer.name}
              onChange={handleInputChange}
              name={'name'}
              required={true}
              errors={errors.name?._errors}
            />
          </div>
          <div className={styles.field}>
            <Input
              label={'MAC Address'}
              value={printer.mac_address}
              onChange={handleInputChange}
              name={'mac_address'}
              required={true}
              errors={errors.mac_address?._errors}
            />
          </div>
          <div className={styles.field}>
            <Select
              placeholder={'Select Printer Type'}
              selectedItem={printer.receipt_print_type}
              items={printerTypes}
              label="Printer Type"
              onSelectItem={memoizedOnChange}
              errors={errors.receipt_print_type?._errors}
            />
          </div>
          <div className={styles.field}>
            <Input
              label={'Model Name'}
              value={printer.model_name}
              onChange={handleInputChange}
              name={'model_name'}
              required={true}
              errors={errors.model_name?._errors}
            />
          </div>
        </div>
        <div className={styles.table_container}>
          {orderTypesLoading ? <BackdropLoading /> : null}
          <PrinterSettingsList printers={printerSettings} onToggleActive={handleOrderTypes} />
        </div>
        <Subtitle title="Select categories to print" />
        <div className={styles.categories_container}>
          {categoriesLoading ? <BackdropLoading /> : null}
          <div className={styles.category_option}>
            <Checkbox
              handleOnChange={handleCategoriesSet}
              item={{ name: '', id: 'All' }}
              isChecked={printer.category_ids?.length === categories?.length}
            />
            Select All
          </div>
          <div className={styles.categories}>
            {categories.map((category) => (
              <div key={category.id} className={styles.category_option}>
                <Checkbox
                  item={{ name: category.name, id: category.id }}
                  handleOnChange={handleCategoriesSet}
                  isChecked={!!printer.category_ids?.find((c) => c === category.id)}
                />
                {category.name}
              </div>
            ))}
          </div>
        </div>
        <div className={styles.buttons}>
          <Button className={styles.button} disabled={statusLoading} title={'Save'} onClick={handleSaveChanges} />
          <Button
            className={styles.button}
            variant={'secondary'}
            disabled={statusLoading}
            title={'Cancel'}
            onClick={handleCancel}
          />
        </div>
      </div>
    </section>
  );
};

export default AddPrinter;
