import React, { useState, useEffect } from 'react';

import { useAppContext } from '../../../../../context/context';

import { getItems } from '../../../../../api/menu_items';
import { getModifierGroups } from '../../../../../api/modifier_sets';

import Modal from '../../../../utilities/modal/modal';
import Button from '../../../../utilities/button/button';
import Input from '../../../../utilities/input/input';
import PageTitle from '../../../../utilities/page-title/page-title';
import TagSelector from '../../../../utilities/tag-selector/tag-selector';
import { convertValueByType } from '../../../../utilities/utils';

import useFetch from '../hooks/useFetch';

import styles from './edit-category-modal.module.scss';

function EditCategoryModal({
  category: incomeCategory,
  isModalOpen,
  closeModal,
  isEditMode,
  isLoading,
  saveChanges,
  errors,
}) {
  const [category, setcategory] = useState(incomeCategory);

  useEffect(() => {
    if (category) {
      setcategory(category);
    }
  }, [category]);

  const {
    data: items,
    isLoading: itemsLoading,
    error: itemsError,
  } = useFetch(() => getItems({}, token), isEditMode ? !!category?.id : true);

  const {
    data: modifiers,
    isLoading: modifiersLoading,
    error: modifiersError,
  } = useFetch(() => getModifierGroups({}, token), isEditMode ? !!category?.id : true);

  const isTotalLoading = itemsLoading || modifiersLoading;

  const transformItems = items.map((item) => ({ value: item.id, label: item.name }));
  const transformModifier = modifiers.map((item) => ({ value: item.id, label: item.name }));

  const { token } = useAppContext();

  const handleInputChange = (e, type) => {
    const { name, value } = e.target;

    setcategory((prev) => ({
      ...prev,
      [name]: convertValueByType(value, type),
    }));
  };

  return (
    <Modal isOpen={isModalOpen} isClickOutside={false} onClose={closeModal} className={styles.modal}>
      <section className={styles.category_edit_container}>
        <div className={styles.head}>
          <PageTitle name={isEditMode ? 'Edit Category' : 'New Category'} className={styles.title} />
          <p className={styles.description}>
            {isEditMode ? `Edit ${incomeCategory.name} category` : 'Create a new category'}
          </p>
        </div>
        <div className={styles.form_container}>
          <div className={styles.fields}>
            <div className={styles.field}>
              <Input
                type="text"
                name="name"
                label="Category name"
                value={category?.name}
                onChange={handleInputChange}
                placeholder="Category name"
                required={true}
                errors={errors.edit?.name?._errors}
              />
            </div>
            <div className={styles.field}>
              <Input
                type="color"
                name="color_code"
                label="Category color"
                value={category?.color_code || '#808080'}
                onChange={handleInputChange}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              />
            </div>
            <div className={styles.field}>
              <TagSelector
                label="Menu items"
                items={transformItems}
                isLoading={isTotalLoading}
                selectedItemsIds={category?.item_ids}
                onChange={(items) => handleInputChange({ target: { name: 'item_ids', value: items } })}
              />
            </div>
            <div className={styles.field}>
              <TagSelector
                label="Modifiers groups"
                items={transformModifier}
                isLoading={isTotalLoading}
                selectedItemsIds={category?.modifier_group_ids}
                onChange={(items) => handleInputChange({ target: { name: 'modifier_group_ids', value: items } })}
              />
            </div>
          </div>

          <div className={styles.action_buttons}>
            <Button
              className={styles.button}
              variant={'secondary'}
              disabled={isLoading}
              title={'Cancel'}
              onClick={closeModal}
            />
            <Button
              className={styles.button}
              disabled={isLoading}
              title={isEditMode ? 'Save Changes' : 'Add category'}
              onClick={() => saveChanges(category)}
            />
          </div>
        </div>
      </section>
    </Modal>
  );
}

export default EditCategoryModal;
