import Switch from '../../../../utilities/switch/switch';
import List from '../../../../utilities/list/list';

import styles from './printers-list.module.scss';

function capitalizeFirstLetter(str) {
  if (!str) return str;
  return str.charAt(0).toUpperCase() + str.slice(1);
}

const PrinterSettingsList = ({ printers, onToggleActive, isLoading }) => {
  const modifiedPrinters = Object.values(
    printers.reduce((acc, item) => {
      const key = item.order_type_id;
      const printerType = item.print_type.toLowerCase();

      if (!acc[key]) {
        acc[key] = {
          id: item.order_type_id,
          name: item.order_types_name,
          print_method: {
            auto_printing: {},
            manual_printing: {},
            status: {},
          },
        };
      }

      acc[key].print_method.auto_printing[printerType] = item.auto_printing;
      acc[key].print_method.manual_printing[printerType] = item.manual_printing;
      acc[key].print_method.status[printerType] = item.status;

      return acc;
    }, {}),
  );

  const renderStatus = (printer) => (
    <div className={styles.actions_cell}>
      {Object.entries(printer.print_method.status).map(([key, value]) => (
        <Switch
          key={key}
          id={JSON.stringify({
            id: printer.id,
            statusChanged: true,
            print_type: key === 'customer' ? 'Customer' : 'Kitchen',
          })}
          isActive={value}
          onSwitch={onToggleActive}
          labelClassName={styles.action}
        />
      ))}
    </div>
  );

  const renderActions = (printer) => (
    <div className={styles.actions_cell}>
      {Object.entries(printer.print_method.auto_printing).map(([key, value]) => (
        <Switch
          key={key}
          id={JSON.stringify({
            id: printer.id,
            print_method: 'auto_printing',
            print_type: key === 'customer' ? 'Customer' : 'Kitchen',
          })}
          isActive={value}
          onSwitch={onToggleActive}
          labelClassName={styles.action}
        />
      ))}
    </div>
  );

  const renderManualActions = (printer) => (
    <div className={styles.actions_cell}>
      {Object.entries(printer.print_method.manual_printing).map(([key, value]) => (
        <Switch
          key={key}
          id={JSON.stringify({
            id: printer.id,
            print_method: 'manual_printing',
            print_type: key === 'customer' ? 'Customer' : 'Kitchen',
          })}
          isActive={value}
          onSwitch={onToggleActive}
          labelClassName={styles.action}
        />
      ))}
    </div>
  );

  const renderPrinterType = (printer) => (
    <div className={styles.print_type_cell}>
      {Object.keys(printer.print_method.status).map((key) => (
        <div key={key} className={styles.receipt_type}>{`${capitalizeFirstLetter(key)} Receipt`}</div>
      ))}
    </div>
  );

  const columnConfig = [
    {
      key: 'name',
      header: 'Printers',
    },
    {
      key: 'print_type',
      header: 'Printer Type',
      cellClassName: styles.print_type,
      render: renderPrinterType,
    },
    {
      key: 'manual_printing',
      header: 'Manual Print',
      cellClassName: styles.actions,
      render: renderManualActions,
    },
    {
      key: 'printer_terminals',
      header: 'Auto Print',
      cellClassName: styles.actions,
      render: renderActions,
    },
    {
      key: 'status',
      header: 'Status',
      cellClassName: styles.actions,
      render: renderStatus,
    },
  ];

  return (
    <List
      data={modifiedPrinters}
      columnConfig={columnConfig}
      isLoading={isLoading}
      isNoDataDisplayed={true}
      isDragable={false}
    />
  );
};

export default PrinterSettingsList;
