import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { useAppContext } from '../../../../../context/context';

import { fetchMenuItem, removeMenuItem } from '../../../../../redux/menuItemsSlice';
import { fetchCategories } from '../../../../../redux/categoriesSlice';
import { fetchTaxes } from '../../../../../redux/taxesSlice';
import { fetchModifiers } from '../../../../../redux/modifierSetsSlice';
import { showModal, hideModal } from '../../../../../redux/modalSlice';

import PageTitle from '../../../../utilities/page-title/page-title';
import Button from '../../../../utilities/button/button';
import { formatISOToDashFormat } from '../../../../utilities/utils';

import clsx from 'clsx';
import styles from './item-details.module.scss';

const ItemDetails = () => {
  // Hooks
  const { token } = useAppContext();
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Determination if the page has id of item to show it
  const isItemId = Boolean(id);

  // Redux Store
  const menu_item = useSelector((state) => state.menu_items.menu_item);
  const categories = useSelector((state) => state.categories.categories);
  const taxes = useSelector((state) => state.taxes.taxes);
  const modifierGroups = useSelector((state) => state.modifierSets.modifierSets);

  const taxesLoading = useSelector((state) => state.taxes.status);
  const categoriesLoading = useSelector((state) => state.categories.status);
  const modifierGroupsLoading = useSelector((state) => state.modifierSets.status);

  const taxesStatusLoading = taxesLoading === 'loading';
  const categoriesStatusLoading = categoriesLoading === 'loading';
  const modifiersStatusLoading = modifierGroupsLoading === 'loading';

  const isTotalLoading = taxesStatusLoading || categoriesStatusLoading || modifiersStatusLoading;

  // Local state
  const [currentTaxes, setCurrentTaxes] = useState([]); // Current taxes
  const [currentCategories, setCurrentCategories] = useState([]); // Current categories
  const [currentModifiers, setCurrentModifiers] = useState([]); // Current modifiers
  //const [itemImageContent, setItemImageContent] = useState(''); // TODO: Add image content when .blob logic will be ready on backend

  const NoImageStub = require('../../../../../assets/icons/image_stub.png');

  // Effects
  useEffect(() => {
    if (isItemId) {
      dispatch(fetchMenuItem({ id, token }));
    }
  }, [dispatch, id, isItemId, token]);

  useEffect(() => {
    if (token) {
      dispatch(fetchCategories({ token }));
      dispatch(fetchTaxes(token));
      dispatch(fetchModifiers({ token }));
    }
  }, [dispatch, token]);

  useEffect(() => {
    if (taxes && taxes.length > 0) {
      const result = taxes.filter((item) => menu_item.tax_ids?.includes(item.id));
      setCurrentTaxes(result);
    }

    if (categories && categories.length > 0) {
      const result = categories.filter((item) => menu_item.category_ids?.includes(item.id));
      setCurrentCategories(result);
    }

    if (modifierGroups && modifierGroups.length > 0) {
      const result = modifierGroups.filter((item) => menu_item.modifier_group_ids?.includes(item.id));
      setCurrentModifiers(result);
    }
  }, [menu_item, taxes, categories, modifierGroups]);

  // Handlers
  const handleDelete = () => {
    dispatch(
      showModal({
        modalId: 'modal-delete-item',
        data: {
          type: 'confirmation',
          title: 'Delete Item',
          message: `Your ${menu_item.name} item will be deleted. You cannot restore it.`,
          actions: [
            {
              title: 'Delete',
              onAction: () => {
                dispatch(removeMenuItem({ id, token })).then(() => {
                  navigate('/menu/items');
                });
                dispatch(hideModal('modal-delete-item'));
              },
            },
            { title: 'Cancel', button_variant: 'secondary', onAction: () => dispatch(hideModal('modal-delete-item')) },
          ],
        },
      }),
    );
  };

  const handleCancel = () => {
    navigate('/menu/items');
  };

  const handleEdit = () => {
    navigate(`/menu/items/${id}/edit`);
  };

  // List of taxes, categories and modifiers with commas between them
  const DataListWithCommas = ({ items }) => {
    if (!items || items.length === 0) return null;

    return (
      <>
        {items.map((item, index) => (
          <span key={item.id || index}>
            {item.name}
            {index !== items.length - 1 && ','}{' '}
          </span>
        ))}
      </>
    );
  };

  return (
    <section className={styles.item_details}>
      <div className={styles.header}>
        <PageTitle name={'Item Details'} />
      </div>

      <div className={styles.content}>
        <div className={styles.title}>{menu_item.name}</div>
        <div className={styles.text}>Created: {formatISOToDashFormat(menu_item.created_at)}</div>
        <div className={styles.text}>Last Edited: {formatISOToDashFormat(menu_item.updated_at)}</div>

        <div className={styles.wrapper}>
          <div className={styles.parameters}>
            <div className={styles.field}>
              <span className={styles.label}>Price</span>
              <div className={styles.info}>$ {menu_item.price}</div>
            </div>
            <div className={clsx(styles.field, styles.column_field)}>
              <span className={styles.label}>Description / Ingridients</span>
              <div className={styles.info}>{menu_item.desc}</div>
            </div>
            <div className={styles.field}>
              <span className={styles.label}>Tax Category</span>
              <div className={styles.info}>
                <DataListWithCommas items={currentTaxes} />
              </div>
            </div>
            <div className={styles.field}>
              <span className={styles.label}>Sales Category</span>
              <div className={styles.info}>
                <DataListWithCommas items={currentCategories} />
              </div>
            </div>
            <div className={clsx(styles.field, styles.column_field)}>
              <span className={styles.label}>Modifier Groups</span>
              <div className={clsx(styles.info, styles.multiple_info)}>
                {currentModifiers && currentModifiers.map((modifier) => <span key={modifier.id}>{modifier.name}</span>)}
              </div>
            </div>
          </div>

          <div className={styles.item_image}>
            <div className={styles.image_content}>
              <div className={styles.image_wrapper} onClick={() => console.log('Image was clicked 1')}>
                {/* {itemImageContent ? (
                  <img src={itemImageContent} alt={`Item ${menu_item.image}`} className={styles.image} />
                ) : ( */}
                <div className={styles.no_image_stub}>
                  <img src={NoImageStub} alt="Click to upload" className={styles.no_image_icon} />
                  <span className={styles.stub_description}>No image</span>
                </div>
                {/*  )} */}
              </div>
            </div>
          </div>
        </div>

        <div className={styles.buttons}>
          <div className={styles.left_side}>
            <Button
              className={styles.button}
              variant={'secondary'}
              disabled={isTotalLoading}
              title={'Delete'}
              onClick={handleDelete}
            />
          </div>
          <div className={styles.right_side}>
            <Button
              className={styles.button}
              variant={'secondary'}
              disabled={isTotalLoading}
              title={'Close'}
              onClick={handleCancel}
            />
            <Button className={styles.button} disabled={isTotalLoading} title={'Edit Item'} onClick={handleEdit} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ItemDetails;
