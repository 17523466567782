import React from 'react';

import { formatISOToUSDate } from '../../../../utilities/utils';

import PageTitle from '../../../../utilities/page-title/page-title';
import BackdropLoading from '../../../../utilities/backdrop-loading/backdrop-loading';
import { camelCaseToWords } from '../../../../utilities/utils';

import UserIcon from '../../../../../assets/img/user.svg';
import styles from './clocked-in-employees.module.scss';

const ClockedInEmployeesWidget = ({ data, startDate, endDate, isLoading }) => {
  const { type, employees } = data;

  const widgetName = type ? camelCaseToWords(type) : 'Clocked In Employees';

  return (
    <div className={styles.wrapper}>
      <PageTitle name={widgetName} className={styles.subtitle} />
      <div className={styles.content}>
        {isLoading && <BackdropLoading />}
        {employees && employees.length > 0 ? (
          employees.map((value) => (
            <div key={value.name} className={styles.employee}>
              <div className={styles.left}>
                <div className={styles.icon_wrapper}>
                  <img src={UserIcon} alt={`${value.name} icon`} className={styles.icon} />
                </div>
                <div className={styles.info_wrapper}>
                  <div className={styles.name}>{value.name}</div>
                  <div className={styles.time}>{formatISOToUSDate(value.time)}</div>
                </div>
              </div>
              <div className={styles.right}>
                <div className={styles.hours}>{value.hours} HR</div>
              </div>
            </div>
          ))
        ) : (
          <div className={styles.no_data}>No data for the selected time period</div>
        )}
      </div>
    </div>
  );
};

export default ClockedInEmployeesWidget;
