import { api } from './base';

export const logIn = async (email, password) => {
  try {
    const response = await api.post('auth/log_in', { email, password });
    return response.data;
  } catch (error) {
    throw error.response?.data || 'Error logging in';
  }
};

export const logOut = async (auth_token, refresh_token) => {
  try {
    const response = await api.post(
      'auth/log_out',
      { auth_token, refresh_token },
      {
        headers: {
          REFRESH_TOKEN: refresh_token,
        },
      },
    );
    return response.data;
  } catch (error) {
    throw error.response?.data || 'Error logging out';
  }
};

export const refreshToken = async (auth_token, refresh_token) => {
  try {
    const response = await api.post('auth/refresh', { auth_token, refresh_token });
    return response.data;
  } catch (error) {
    throw error.response?.data || 'Error refresh token';
  }
};

export const forgotPassword = async (email) => {
  try {
    const response = await api.post('auth/forgot_password', { email });
    return response.data;
  } catch (error) {
    throw error.response?.data || 'This email address is not associated with any account';
  }
};

export const resetPassword = async (email, new_password, token) => {
  try {
    const response = await api.post(
      'auth/update_password',
      { email, new_password },
      {
        headers: {
          Authorization: token,
        },
      },
    );
    return response.data;
  } catch (error) {
    throw error.response?.data || 'Error resetting password';
  }
};
