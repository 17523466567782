import { z } from 'zod';

const sub_modifiersRegistrationSchema = z.object({
  name: z.string().max(60, { message: 'Please enter 60 characters or fewer.' }).nullable().optional(),
  price: z.number().nullable().optional(),
});

const modifiersRegistrationSchema = z.object({
  name: z.string().max(60, { message: 'Please enter 60 characters or fewer.' }).nullable().optional(),
  min: z.number().max(60, { message: 'Please enter 60 characters or fewer.' }),
  max: z.number().max(30, { message: 'Please enter 30 characters or fewer.' }),
  price: z.number().nullable().optional(),
  sub_modifiers: z.array(sub_modifiersRegistrationSchema),
});

export const registrationSchema = z.object({
  name: z
    .string({ required_error: 'Please enter Modifier Group Name' })
    .min(1, { message: 'Please enter Modifier Group Name' }),
  min: z.number().max(60, { message: 'Please enter 60 characters or fewer.' }),
  max: z.number().max(30, { message: 'Please enter 30 characters or fewer.' }),
  modifiers: z.array(modifiersRegistrationSchema),
});
