import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import 'react-datepicker/dist/react-datepicker.css';

import { updateExistingTimesheetDetails } from '../../../../redux/timesheetSlice';
import { useAppContext } from '../../../../context/context';
import {
  transformToISOFormat,
  receiveTimeWithoutTimeZoneCorrection,
  transformToISOFormatWithoutTimeZoneCorrection,
} from '../../../utilities/utils';

import Button from '../../../utilities/button/button';
import PageTitle from '../../../utilities/page-title/page-title';

import TimesheetDetailsEditList from './timesheet-details-edit-list';

import styles from './edit-timesheet-details.module.scss';

function EditTimesheetDetails() {
  // Hooks
  const dispatch = useDispatch();
  const { token } = useAppContext();
  const { id } = useParams();
  const [urlParams] = useSearchParams();
  const navigate = useNavigate();

  // Store
  const status = useSelector((state) => state.timesheet.status);

  const isLoading = status === 'loading';

  // Local state
  const [timesheetDetails, setTimesheetDetails] = useState({});

  // Effect (Set data from URL params)
  useEffect(() => {
    setTimesheetDetails({
      recordedId: id ?? 0,
      employeeName: urlParams?.get('employee_name'),
      recordId: urlParams?.get('record_id'),
      fromDate: receiveTimeWithoutTimeZoneCorrection(urlParams?.get('from_date')),
      toDate: receiveTimeWithoutTimeZoneCorrection(urlParams?.get('to_date')),
      fromPeriod: urlParams?.get('from_date'),
      toPeriod: urlParams?.get('to_date'),
      status: status ?? '-',
    });
  }, [id, status, urlParams]);

  // Handlers
  const handleUpdateButtonClick = async () => {
    const newDetails = {
      employee_id: Number(id),
      records: [
        {
          id: timesheetDetails?.recordedId,
          clock_in: transformToISOFormatWithoutTimeZoneCorrection(timesheetDetails?.fromDate),
          clock_out: transformToISOFormatWithoutTimeZoneCorrection(timesheetDetails?.toDate),
        },
      ],
    };

    try {
      await dispatch(
        updateExistingTimesheetDetails({
          updatedDetails: newDetails,
          token,
        }),
      ).unwrap();
    } catch (error) {
      console.error('Failed to update timesheet:', error);
    }

    handleBackButtonClick();
  };

  const handleBackButtonClick = () => {
    const path = `/employee/timesheet/${id}/timesheet_details?employee_name=${timesheetDetails.employeeName}&from_date=${timesheetDetails.fromPeriod}&to_date=${timesheetDetails.toPeriod}`;
    navigate(path);
  };

  const handleSelectDate = (date, field) => {
    if (date instanceof Date) {
      setTimesheetDetails((prev) => ({
        ...prev,
        [field]: date,
      }));
    }
  };

  return (
    <div className={styles.edit_timesheet_details}>
      <div className={styles.head_container}>
        <div className={styles.back_button}>
          <button type="button" onClick={handleBackButtonClick}>
            Back to Timesheet
          </button>
        </div>
        <div className={styles.title_and_date}>
          <PageTitle name={`Adjust Time: ${timesheetDetails.employeeName}`} />
          <div className={styles.date}>
            {`Date: ${transformToISOFormat(timesheetDetails.fromDate, true)} - ${transformToISOFormat(timesheetDetails.toDate, true)}`}
          </div>
        </div>
      </div>
      <div className={styles.list_container}>
        <TimesheetDetailsEditList
          timesheetDetails={[timesheetDetails]}
          onEdit={handleSelectDate}
          isLoading={isLoading}
        />
        <div className={styles.buttons}>
          <Button title="Update" onClick={handleUpdateButtonClick} />
          <Button title="Cancel" onClick={handleBackButtonClick} />
        </div>
      </div>
    </div>
  );
}

export default EditTimesheetDetails;
