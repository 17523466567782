import NoItems from '../../utilities/no-items/no-items';
import BackdropLoading from '../../utilities/backdrop-loading/backdrop-loading';

import { IoCubeOutline } from 'react-icons/io5';

const ListStateRenderer = ({
  isLoading = false,
  length = 0,
  isNoDataDisplayed = true,
  noDataComponent: NoData,
  noDataProps,
  children,
}) => {
  if (length > 0 && isLoading) {
    return (
      <>
        {children}
        <BackdropLoading />
      </>
    );
  }

  if (length < 1 && isLoading) {
    return (
      <div style={{ height: '100%' }}>
        <BackdropLoading />
      </div>
    );
  }

  if (length < 1 && !isLoading && !isNoDataDisplayed) {
    return (
      <>
        {NoData ? (
          <NoData {...noDataProps} />
        ) : (
          <div>
            <NoItems title={'No data available'} icon={IoCubeOutline} />
          </div>
        )}
      </>
    );
  }

  return children;
};

export default ListStateRenderer;
