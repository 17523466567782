import React, { useEffect, useCallback } from 'react';
import store from './redux/store.js';
import { clearAuth, logoutUser, refreshToken } from './redux/authSlice';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { useBeforeUnload } from 'react-router-dom';

import { checkAuthState } from './redux/authSlice';

import ServiceWrapper from './components/layout/main-wrapper/service-wrapper.jsx';
import Login from './components/auth/login/authentication.jsx';
import ForgotPassword from './components/auth/forgot-password/forgot-password.jsx';
import ResetPassword from './components/auth/reset-password/reset-password.jsx';
import NewRegistration from './components/auth/new-registration/new-registration.jsx';
import BusinessInfo from './components/auth/business-info/business-info.jsx';
import Home from './components/layout/home/home.jsx';

import styles from './assets/styles/app.scss';

function App() {
  const dispatch = useDispatch();

  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  if (isAuthenticated) {
    dispatch(checkAuthState());
  }

  return (
    <>
      <Router>
        <div className={styles.app} style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
          <Routes>
            {!isAuthenticated ? (
              <Route path="/" element={<ServiceWrapper />}>
                <Route index element={<Navigate to="/login" replace />} />
                <Route path="login" element={<Login />} />
                <Route path="forgot-password" element={<ForgotPassword />} />
                <Route path="new-registration" element={<NewRegistration />} />
                <Route path="business-info" element={<BusinessInfo />} />
                <Route path="reset-password" element={<ResetPassword />} />
                <Route path="*" element={<Navigate to="/login" replace />} />
              </Route>
            ) : (
              <Route path="/" element={<ServiceWrapper />}>
                <Route path="/" element={<Navigate to="/dashboard" replace />} />
                <Route path="*" element={<Home />} />
              </Route>
            )}
          </Routes>
        </div>
      </Router>
    </>
  );
}

export default App;
