import { useState, useEffect, useCallback, useMemo } from 'react';

import { useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { useAppContext } from '../../../../context/context';

import { fetchCategory, clearCurrentCategory } from '../../../../redux/categoriesSlice';
import { fetchMenuItems, clearMenuItems, updateExistingMenuItemStatusPOS } from '../../../../redux/menuItemsSlice';
import { fetchModifiers, clearModifierGroups } from '../../../../redux/modifierSetsSlice';
import { addNotification } from '../../../../redux/tooltipSlice';

import PageTitle from '../../../utilities/page-title/page-title';
import Search from '../../../utilities/search/search';
import Button from '../../../utilities/button/button';
import BackdropLoading from '../../../utilities/backdrop-loading/backdrop-loading';
import Toggler from '../../../utilities/toggler/toggler';
import ThreeDotsLoading from '../../../utilities/three-dots-loading/three-dots-loading';

import { LiaLayerGroupSolid as ModifiersGroup } from 'react-icons/lia';
import { MdFastfood as MenuItems } from 'react-icons/md';

import { MdEdit } from 'react-icons/md';

import { ItemCard, ItemCardSection } from './components/item-cards';

import EditCategoryModal from './modals/edit-category-modal';
import useCategoryManager from './hooks/useCategoryManager';

import styles from './categories.module.scss';

const HIDE_STATUS = {
  0: 'UnHide',
  1: 'HideForToday',
  2: 'HideForIndefinitely',
};

function Category() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useAppContext();

  const categoryStatus = useSelector((state) => state.categories.status);
  const currentCategory = useSelector((state) => state.categories.category);
  const modifierSetsStatus = useSelector((state) => state.modifierSets.status);
  const modifierSets = useSelector((state) => state.modifierSets.modifierSets);
  const menuItemsStatus = useSelector((state) => state.menu_items.status);
  const menuItems = useSelector((state) => state.menu_items.menu_items);

  const [toggleItemStatus, setToggleItemStatus] = useState(true);

  const hiddenMenuItems = useMemo(
    () => menuItems.filter((item) => item.hide_status === HIDE_STATUS[2] || item.hide_status === HIDE_STATUS[1]),
    [menuItems],
  );
  const unhiddenMenuItems = useMemo(() => menuItems.filter((item) => item.hide_status === HIDE_STATUS[0]), [menuItems]);

  const filteredMenuItems = toggleItemStatus ? unhiddenMenuItems : hiddenMenuItems;

  const isTotalLoading =
    categoryStatus === 'loading' ||
    categoryStatus === 'idle' ||
    menuItemsStatus === 'loading' ||
    menuItemsStatus === 'idle' ||
    modifierSetsStatus === 'loading' ||
    modifierSetsStatus === 'idle';

  const { category, isEditMode, isEditModalOpen, handleSaveCategory, handleShowEditModal, handleModalClose, errors } =
    useCategoryManager({ currentCategory: currentCategory });

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(fetchCategory({ id, token })).unwrap();
      } catch (error) {
        dispatch(addNotification({ message: `Error fetching data: ${error}`, status: 'failed' }));
      }
    };

    fetchData();

    return () => {
      dispatch(clearMenuItems());
      dispatch(clearCurrentCategory());
      dispatch(clearModifierGroups());
    };
  }, [dispatch, token, id]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (category?.id) {
          await dispatch(fetchMenuItems({ itemsDetails: { category_ids: category?.id }, token })).unwrap();
          await dispatch(fetchModifiers({ modifierDetails: { category_ids: category?.id }, token })).unwrap();
        }
      } catch (error) {
        dispatch(addNotification({ message: `Error fetching data: ${error}`, status: 'failed' }));
      }
    };

    fetchData();
  }, [dispatch, token, id, category]);

  const handleToggleStatusPOS = useCallback(
    (id, status) => {
      dispatch(updateExistingMenuItemStatusPOS({ id, status: status ? 0 : 2, token }));
    },
    [dispatch, token],
  );

  return (
    <div className={styles.main_container}>
      <div className={styles.head_container}>
        <div className={styles.title_and_button}>
          <PageTitle
            name={
              <div className={styles.title_container} onClick={() => navigate('/menu/categories')}>
                <span className={styles.title} onClick={() => navigate('/menu/categories')}>
                  Categories
                </span>
                <span> - </span>
                <span>{isTotalLoading ? <ThreeDotsLoading /> : category?.name}</span>
              </div>
            }
          />
          <div>
            <Button
              className={styles.add_button}
              title={'Edit Category'}
              onClick={() => handleShowEditModal(currentCategory?.id)}
              prefixIcon={<MdEdit />}
            />
          </div>
        </div>
        <div className={styles.list_controller}>
          <div className={styles.search}>
            <Search placeholder="Search..." onSearchResults={() => {}} onClear={() => {}} autoFilter={false} />
          </div>
          <div className={styles.toggler}>
            <Toggler
              toggle={toggleItemStatus}
              onToggle={() => setToggleItemStatus((prev) => !prev)}
              titles={{ active: 'Show on POS', inactive: 'Hidden on POS' }}
              toggleWidth={256}
            />
          </div>
        </div>
      </div>
      <div className={styles.list_container}>
        {isTotalLoading && filteredMenuItems.length > 1 && <BackdropLoading />}
        <>
          <div className={styles.menu_items}>
            <h5 className={styles.sub_title}>Menu items</h5>
            <div className={styles.items}>
              <ItemCardSection
                length={filteredMenuItems.length}
                isLoading={isTotalLoading}
                noItemsProps={{
                  title: toggleItemStatus ? 'No menu items' : 'No hidden menu items',
                  description: toggleItemStatus ? 'Click on the "Edit category" and set to this category' : '',
                }}
              >
                {filteredMenuItems.map((item) => {
                  return (
                    <ItemCard
                      item={item}
                      onClick={(id) => navigate(`/menu/items/${id}/edit`)}
                      toggleOnPOS={handleToggleStatusPOS}
                      tagLabel={category?.name}
                      tagColor={category?.color_code}
                      commonIcon={MenuItems}
                      key={item.id}
                    />
                  );
                })}
              </ItemCardSection>
            </div>
          </div>
          <div className={styles.modifier_groups}>
            <h5 className={styles.sub_title}>Modifier groups</h5>
            <div className={styles.items}>
              <ItemCardSection
                length={modifierSets.length}
                isLoading={isTotalLoading}
                noItemsProps={{
                  title: 'No modifier groups',
                  description: 'Click on the "Edit category" and set to this category',
                }}
              >
                {modifierSets.map((item) => {
                  return (
                    <ItemCard
                      item={item}
                      onClick={(id) => navigate(`/menu/modifiers?editId=${id}`)}
                      tagLabel={category?.name}
                      tagColor={category?.color_code}
                      renderComponents={{ cost: false, onPosController: false }}
                      commonIcon={ModifiersGroup}
                      key={item.id}
                    />
                  );
                })}
              </ItemCardSection>
            </div>
          </div>
        </>
      </div>
      {isEditModalOpen && (
        <EditCategoryModal
          category={category}
          saveChanges={handleSaveCategory}
          isModalOpen={isEditModalOpen}
          isEditMode={isEditMode}
          closeModal={handleModalClose}
          errors={errors}
        />
      )}
    </div>
  );
}

export default Category;
