import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { fetchCancelOrdersReasons } from '../../../../redux/cancelOrderReasonsSlice';

import { useAppContext } from '../../../../context/context';
import usePagination from '../order-types/usePagination';

import PageTitle from '../../../utilities/page-title/page-title';
import Pagination from '../../../utilities/pagination/pagination';
import Button from '../../../utilities/button/button';

import CancelOrderReasonList from './cancel-order-reasons-list';
import CancelOrderReasonForm from './forms/cancel-order-reasons-form';
import useWastageReasonsManagement from './hooks/useCancelReasonsManagement';

import { compareArraysOfObjectsById } from '../../../utilities/utils';

import styles from './cancel-order-reasons.module.scss';

const PAGE_SIZE_OPTIONS = [
  { label: 5, value: 5 },
  { label: 6, value: 6 },
  { label: 7, value: 7 },
  { label: 8, value: 8 },
  { label: 10, value: 10 },
  { label: 15, value: 15 },
  { label: 20, value: 20 },
  { label: 25, value: 25 },
];

const DEFAULT_PAGINATION = {
  currentPage: 1,
  itemsPerPage: 5,
};

function CancelOrders() {
  const cancelReasonsStatus = useSelector((state) => state.cancelOrderReasons.status);
  const cancelReasons = useSelector((state) => state.cancelOrderReasons.cancelOrderReasons);

  const isLoading = cancelReasonsStatus === 'loading' || cancelReasonsStatus === 'idle';

  const dispatch = useDispatch();
  const { token } = useAppContext();

  const {
    currentPageItems,
    page,
    perPage,
    isPrevActive,
    isNextActive,
    handleSelectPageCount,
    handleClickPrev,
    handleClickNext,
  } = usePagination(cancelReasons, DEFAULT_PAGINATION.currentPage, DEFAULT_PAGINATION.itemsPerPage);

  const {
    cancelReasonsState,
    initialCancelReasonsState,
    handleAddReason,
    handleToggleReasonActive,
    handleconfirmAndDeleteReason,
    handleUpdateReasonName,
    handleChangeList,
    handleSaveChanges,
    handleReorderReasons,
  } = useWastageReasonsManagement(currentPageItems);

  const hasUnsavedChanges = useMemo(
    () => compareArraysOfObjectsById(cancelReasonsState, initialCancelReasonsState),
    [initialCancelReasonsState, cancelReasonsState],
  );

  useEffect(() => {
    if (token) {
      dispatch(fetchCancelOrdersReasons({ token }));
    }
  }, [dispatch, token]);

  return (
    <div className={styles.main_container}>
      <div className={styles.head_container}>
        <PageTitle name={'Cancel Order Reasons'} />
      </div>
      <div className={styles.content}>
        <CancelOrderReasonForm onAdd={handleAddReason} />
        <CancelOrderReasonList
          orderReasons={cancelReasonsState}
          onInputChange={handleUpdateReasonName}
          onListChange={handleChangeList}
          onDelete={handleconfirmAndDeleteReason}
          onToggleActive={handleToggleReasonActive}
          isLoading={isLoading}
          isFailed={cancelReasonsStatus === 'failed'}
          onDrag={handleReorderReasons}
        />
        <Pagination
          pageSizeOptions={PAGE_SIZE_OPTIONS}
          numberOfItems={cancelReasonsState.length}
          currentPage={page}
          currentPageSize={perPage}
          onPageSizeChange={handleSelectPageCount}
          inputPaginationControl={{
            isActive: true,
            isPrevActive,
            isNextActive,
          }}
          onPrev={handleClickPrev}
          onNext={handleClickNext}
        />
        {!hasUnsavedChanges && (
          <div className={styles.action_buttons}>
            <Button className={styles.save_button} title={'Update'} onClick={handleSaveChanges} />
          </div>
        )}
      </div>
    </div>
  );
}

export default CancelOrders;
