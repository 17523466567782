import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  fetchMenuItems,
  createMenuItem,
  removeMenuItem,
  updateExistingMenuItemStatusPOS,
  updateExistingMenuItemStatusWebsite,
} from '../../../../redux/menuItemsSlice';
import { fetchCategories } from '../../../../redux/categoriesSlice';
import { addNotification } from '../../../../redux/tooltipSlice';

import { IoIosArrowDown } from 'react-icons/io';

import { useAppContext } from '../../../../context/context';
import usePagination from '../../business/order-types/usePagination';

import PageTitle from '../../../../components/utilities/page-title/page-title';
import Selector from '../../../../components//utilities/selector/selector';
import Search from '../../../../components//utilities/search/search';
import Button from '../../../../components/utilities/button/button';
import Pagination from '../../../../components/utilities/pagination/pagination';

import MenuItemsList from './items-list';

import styles from './hidden-items.module.scss';

const PAGE_SIZE_OPTIONS = [
  { label: 5, value: 5 },
  { label: 6, value: 6 },
  { label: 7, value: 7 },
  { label: 8, value: 8 },
  { label: 10, value: 10 },
  { label: 15, value: 15 },
  { label: 20, value: 20 },
  { label: 25, value: 25 },
];

const DEFAULT_PAGINATION = {
  currentPage: 1,
  itemsPerPage: 5,
};

function HiddenItems() {
  const status = useSelector((state) => state.menu_items.status);
  const menu_items = useSelector((state) => state.menu_items.menu_items);
  const categories = useSelector((state) => state.categories.categories);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useAppContext();

  const [filteredMenuItems, setFilteredMenuItems] = useState(menu_items);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState(0);
  const selectorOptions = useMemo(() => {
    return categories.map((item) => ({
      value: item.id,
      label: item.name,
    }));
  }, [categories]);

  // For delete all checked items
  const [selectedItemsIds, setSelectedItemsIds] = useState([]);

  const { page, perPage, isPrevActive, isNextActive, handleSelectPageCount, handleClickPrev, handleClickNext } =
    usePagination(filteredMenuItems, DEFAULT_PAGINATION.currentPage, DEFAULT_PAGINATION.itemsPerPage);

  const applyFilters = useCallback(() => {
    // Here should be array sorted by field "sort" but right now on database the value of field "sort is 0 for all items, so I use sort by field "id" to have the stable order of the items in the array
    let filtered = [...menu_items]
      .filter((item) => item.hide_status === 'HideForIndefinitely')
      .sort((a, b) => a.id - b.id);

    if (selectedCategory !== 0) {
      filtered = filtered.filter((item) => item.category_id === Number(selectedCategory));
    }

    if (searchTerm !== '') {
      filtered = filtered.filter((item) => item.name.toLowerCase().includes(searchTerm.toLowerCase()));
    }

    setFilteredMenuItems(filtered);
  }, [menu_items, selectedCategory, searchTerm]);

  useEffect(() => {
    if (token) {
      dispatch(fetchMenuItems(token));
      dispatch(fetchCategories({ token }));
    }
  }, [dispatch, token]);

  useEffect(() => {
    const sortedMenuItems = [...menu_items].sort((a, b) => a.id - b.id);
    setFilteredMenuItems(sortedMenuItems);
  }, [menu_items]);

  useEffect(() => {
    applyFilters();
  }, [menu_items, selectedCategory, searchTerm, applyFilters]);

  const handleAction = (message, status) => {
    dispatch(addNotification({ message, status }));
  };

  const handleSetResult = (term) => {
    setSearchTerm(term);
  };

  const handleFilterByCategory = (selectedValue) => {
    setSelectedCategory(Number(selectedValue));
  };

  const handleToggleActive = useCallback(
    (id, value, type) => {
      const status = value ? 1 : 2;

      if (type === 'pos') {
        dispatch(updateExistingMenuItemStatusPOS({ id: Number(id), status, token }));
      } else if (type === 'website') {
        dispatch(updateExistingMenuItemStatusWebsite({ id: Number(id), status, token }));
      }
    },
    [dispatch, token],
  );

  const handleCopyItem = (id) => {
    if (!id || !Array.isArray(filteredMenuItems)) {
      handleAction('Invalid ID or menu items is not an array', 'failed');
      return;
    }

    const duplicatedItem = filteredMenuItems.find((item) => item.id === id);

    if (!duplicatedItem) {
      handleAction('No menu item found', 'failed');
      return;
    }

    const newDetails = {
      active: duplicatedItem.active || true,
      category_id: duplicatedItem.category_id || 0,
      cost: duplicatedItem.cost || 0,
      desc: duplicatedItem.desc || null,
      kitchen_name: duplicatedItem.kitchen_name || '',
      //modifier_set_ids: duplicatedItem.modifier_and_option_sets || [], // we need take this value from real item
      modifier_set_ids: [], //temporary solution
      name: `${duplicatedItem.name}1`,
      price: duplicatedItem.price || 0,
      image: duplicatedItem.image || null,
      price_type: duplicatedItem.price_type || 'Variable',
      product_code: duplicatedItem.product_code || '',
      quantity: duplicatedItem.quantity || 0,
      sku: duplicatedItem.sku || '',
      //tax_ids: duplicatedItem.taxes_data || [0], // we need take this value from real item
      tax_ids: [], //temporary solution
      //variations_attributes: duplicatedItem.variations_attributes // we need take this value from real item
      variations_attributes: duplicatedItem.variations_attributes || [], //temporary solution
      //item_modifier_sets_sort: duplicatedItem.modifier_and_option_sets || [],  // we need take this value from real item
      item_modifier_sets_sort: duplicatedItem.item_modifier_sets_sort || [], //temporary solution
      hide_status: duplicatedItem.hide_status || 1,
      web_site_hide_status: duplicatedItem.web_site_hide_status || 1,
    };

    if (!token) {
      handleAction('The token is missing', 'failed');
      return;
    }

    dispatch(createMenuItem({ newDetails, token }));
    handleAction('Item was cloned succeesfully', 'succeeded');
  };

  const handleEditItem = (id) => {
    navigate(`/menu/items/${id}/edit`);
  };

  const handleDeleteItem = (id) => {
    dispatch(removeMenuItem({ id, token }));
  };

  const handleDeleteAllSelected = () => {
    if (selectedItemsIds.length > 0) {
      selectedItemsIds.forEach((element) => {
        dispatch(removeMenuItem({ id: element, token }));
      });
      setSelectedItemsIds([]);
    }
  };

  const handleSearchClean = () => {
    setSearchTerm('');
  };

  return (
    <div className={styles.items}>
      <PageTitle name={'Hidden Items'} />
      <div className={styles.list_controller}>
        <div className={styles.filter_container}>
          <div className={styles.role_selector}>
            <Selector
              icon={IoIosArrowDown}
              items={selectorOptions}
              additionalItems={[{ label: 'All', value: 0 }]}
              selectedItem={selectedCategory}
              onSelectItem={handleFilterByCategory}
            />
          </div>
          <div className={styles.search}>
            <Search
              placeholder="Search Item Name or SKU"
              onSearchResults={handleSetResult}
              onClear={handleSearchClean}
              autoFilter={false}
            />
          </div>
        </div>

        <div className={styles.buttons}>
          <Button className={styles.delete_button} title={'Delete Selected'} onClick={handleDeleteAllSelected} />
          {/*           <Button
            className={styles.add_button}
            title={"Add New Item"}
            onClick={handleAddNewItem}
          /> */}
        </div>
      </div>
      <MenuItemsList
        items={filteredMenuItems}
        categories={categories}
        onCopy={handleCopyItem}
        onEdit={handleEditItem}
        onDelete={handleDeleteItem}
        onToggleActive={handleToggleActive}
        selectedItemsIds={selectedItemsIds}
        setSelectedItemsIds={setSelectedItemsIds}
        isLoading={status === 'loading'}
      />
      <Pagination
        pageSizeOptions={PAGE_SIZE_OPTIONS}
        numberOfItems={menu_items.length}
        currentPage={page}
        currentPageSize={perPage}
        onPageSizeChange={handleSelectPageCount}
        inputPaginationControl={{
          isActive: true,
          isPrevActive,
          isNextActive,
        }}
        onPrev={handleClickPrev}
        onNext={handleClickNext}
        style={{ marginTop: '14px' }}
      />
    </div>
  );
}

export default HiddenItems;
