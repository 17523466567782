import React, { useCallback, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../../../context/context';
import { updateExistingCustomer, fetchCustomer, clearCurrentCustomer } from '../../../redux/customersSlice';
import { addNotification } from '../../../redux/tooltipSlice';

import clsx from 'clsx';

import PageTitle from '../../utilities/page-title/page-title';
import Input from '../../utilities/input/input';
import Button from '../../utilities/button/button';
import DatePicker from '../../utilities/date-picker/date-picker';
import BackdropLoading from '../../utilities/backdrop-loading/backdrop-loading';
import DynamicFormFields from '../../utilities/dynamic-form-fields/dynamic-form-fields ';

import AddressForm from './forms/addresses/adrdess-form';
import PhonesForm from './forms/phones/phones-form';

import { registrationSchema } from './validation';
import useFormValidation from '../../utilities/useFormValidation';
import { transformToISOFormat } from '../../utilities/utils';

import { isObjectEmpty, convertValueByType } from '../../utilities/utils';

import styles from './customers.module.scss';

const DATE_FORMAT = 'MM/dd/yyyy';

const getCustomer = (customers, id) => {
  return customers.find((item) => item.id === parseFloat(id));
};

const EditCustomer = () => {
  const { id } = useParams();
  const { token } = useAppContext();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const customers = useSelector((state) => state.customers.customers);
  const customersStatus = useSelector((state) => state.customers.status);
  const currentCustomer = useSelector((state) => state.customers.currentCustomer);
  const [customer, setCustomer] = useState(getCustomer(customers, id) || currentCustomer);

  const { errors, validate } = useFormValidation(registrationSchema);

  const statusLoading = customersStatus === 'loading';

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (customersStatus === 'idle') {
          await dispatch(fetchCustomer({ id, token })).unwrap();
        }

        if (customersStatus === 'succeeded' && !isObjectEmpty(currentCustomer)) {
          const formatAddresses = (addresses) => addresses.map((addr) => ({ ...addr }));
          const formatPhones = (phones) => phones.map((phone) => ({ ...phone }));

          setCustomer(() => {
            const { addresses = [], phones = [], ...rest } = currentCustomer;

            return {
              ...rest,
              addresses_attributes: formatAddresses(addresses),
              phones_attributes: formatPhones(phones),
            };
          });
        }
      } catch (error) {
        dispatch(addNotification({ message: `Error fetching data: ${error}`, status: 'failed' }));
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, token, customersStatus, id]);

  const handleInputChange = (e, type) => {
    const { name, value } = e.target;
    setCustomer((prev) => ({
      ...prev,
      [name]: convertValueByType(value, type),
    }));
  };

  const handleCancel = () => {
    navigate('/customers');
    dispatch(clearCurrentCustomer());
  };

  const handleSaveChanges = async () => {
    if (validate(customer)) {
      try {
        await dispatch(
          updateExistingCustomer({
            id,
            updatedDetails: {
              ...customer,
              addresses_attributes: customer.addresses_attributes.map(({ id, ...rest }) => rest),
              phones_attributes: customer.phones_attributes.map(({ id, ...rest }) => rest),
            },
            token,
          }),
        ).unwrap();
        navigate('/customers');
        dispatch(clearCurrentCustomer());
      } catch {}
    }
  };

  const handlePhoneChange = useCallback((fields) => {
    handleInputChange({
      target: { name: 'phones_attributes', value: fields },
    });
  }, []);

  // const handleBillingAddressChange = useCallback(
  //   (billingFields = []) => {
  //     const deliveryFields = customer.addresses_attributes?.filter((a) => a.type_of_address === 'Shipping') || [];

  //     handleInputChange({
  //       target: {
  //         name: 'addresses_attributes',
  //         value: [...deliveryFields, ...billingFields],
  //       },
  //     });
  //   },
  //   [customer.addresses_attributes],
  // );

  const handleDeliveryAddressChange = useCallback(
    (deliveryFields = []) => {
      const billingFields = customer.addresses_attributes?.filter((a) => a.type_of_address === 'Billing') || [];

      handleInputChange({
        target: {
          name: 'addresses_attributes',
          value: [...billingFields, ...deliveryFields],
        },
      });
    },
    [customer.addresses_attributes],
  );

  const handleSelectDate = (date, fieldName) => {
    const result = transformToISOFormat(date, true);
    setCustomer((prev) => ({
      ...prev,
      [fieldName]: result,
    }));
  };

  if (!customer) {
    return null;
  }

  return (
    <section className={styles.customer_section}>
      <div className={styles.head_container}>
        <PageTitle name={'Edit Customer'} />
      </div>
      <div className={styles.form_container}>
        <div className={styles.fields}>
          {statusLoading ? <BackdropLoading /> : null}
          <div className={clsx(styles.name_fields, styles.block)}>
            <div className={styles.field}>
              <Input
                label={'First name'}
                value={customer?.first_name}
                onChange={handleInputChange}
                name={'first_name'}
                required={true}
                errors={errors.first_name?._errors}
              />
            </div>
            <div className={styles.field}>
              <Input
                label={'Last name'}
                value={customer?.last_name}
                onChange={handleInputChange}
                name={'last_name'}
                errors={errors.last_name?._errors}
              />
            </div>
          </div>
          <div className={clsx(styles.field, styles.block)}>
            <DynamicFormFields
              value={customer?.phones_attributes}
              label={'Phone Numbers'}
              fieldItem={PhonesForm}
              fieldType={'text'}
              fieldProps={{ style: { margin: '0 auto' } }}
              maxFields={1}
              addButtonTitle={'Add Phone'}
              onChange={handlePhoneChange}
              containerClass={styles.phone_container}
              buttonClassName={styles.dynamic_form_button}
              bottomLine={true}
            />
          </div>
          <div className={clsx(styles.field, styles.block)}>
            <Input
              label={'Email Address'}
              value={customer?.email}
              onChange={handleInputChange}
              name={'email'}
              required={true}
              errors={errors.email?._errors}
            />
          </div>
          <div className={clsx(styles.field, styles.block)}>
            <DynamicFormFields
              value={customer.addresses_attributes?.filter((a) => a.type_of_address === 'Shipping')}
              label={'Delivery Address'}
              fieldItem={AddressForm}
              fieldType={'text'}
              fieldProps={{ style: { margin: '0 auto' } }}
              addItemAttributes={{ type_of_address: 'Shipping' }}
              addButtonTitle={'Add Address'}
              onChange={handleDeliveryAddressChange}
              bottomLine={true}
              buttonClassName={styles.dynamic_form_button}
              errors={errors?.addresses_attributes}
            />
          </div>
          {/* <div className={clsx(styles.field, styles.block)}>
              <DynamicFormFields
                value={customer.addresses_attributes?.filter((a) => a.type_of_address === 'Billing')}
                label={'Billing Address'}
                fieldItem={AddressForm}
                fieldType={'text'}
                fieldProps={{ style: { margin: '0 auto' } }}
                addItemAttributes={{ type_of_address: 'Billing' }}
                addButtonTitle={'Add Address'}
                onChange={handleBillingAddressChange}
                bottomLine={true}
                errors={errors?.addresses_attributes}
              />
            </div> */}
          <div className={clsx(styles.field, styles.block)}>
            <Input label={'Company'} value={customer?.company} onChange={handleInputChange} name={'company'} />
          </div>
          <div className={clsx(styles.field, styles.block)}>
            <DatePicker
              selected={customer.birth_date}
              label="Birthday"
              name="birth_date"
              onChange={(date) => handleSelectDate(date, 'birth_date')}
              dateFormat={DATE_FORMAT}
              className={styles.datepicker}
              placeholderText="Select a date"
              errors={errors.birth_date?._errors}
              wrapperClassName={styles.picker_wrapper}
            />
          </div>
        </div>
        <div className={styles.buttons}>
          <Button
            className={styles.button}
            disabled={statusLoading}
            title={'Update Customer'}
            onClick={handleSaveChanges}
          />
          <Button
            className={styles.button}
            variant={'secondary'}
            disabled={statusLoading}
            title={'Cancel'}
            onClick={handleCancel}
          />
        </div>
      </div>
    </section>
  );
};

export default EditCustomer;
