import { api, handleApiResponse } from './base';

export const getBusinessInformation = (id, token) =>
  handleApiResponse(() =>
    api.get(`locations/${id}/business_details`, {
      headers: {
        Authorization: token,
      },
    }),
  );

export const updateBusinessInformation = (id, updatedDetails, token) =>
  handleApiResponse(() =>
    api.put(`locations/${id}/business_details`, updatedDetails, {
      headers: {
        Authorization: token,
      },
    }),
  );
