import Modal from '../../../../utilities/modal/modal';
import Button from '../../../../utilities/button/button';
import PageTitle from '../../../../utilities/page-title/page-title';
import BackdropLoading from '../../../../utilities/backdrop-loading/backdrop-loading';

import ModifierGroupForm from '../forms/modifier-group-form';

import styles from './edit-modifier-group.module.scss';

function EditModifierGroupModal({
  modifierGroup,
  setModifierGroup,
  isModalOpen,
  closeModal,
  isEditMode,
  isLoading,
  saveChanges,
  errors,
}) {
  const handleInputChange = (value) => {
    setModifierGroup((prev) => ({
      ...prev,
      modifierGroup: { ...value },
    }));
  };

  return (
    <Modal isOpen={isModalOpen} isClickOutside={false} onClose={closeModal} className={styles.modal}>
      <section className={styles.modifier_group_edit_container}>
        <div className={styles.head}>
          <PageTitle
            name={
              isEditMode
                ? `Edit: ${modifierGroup.name || 'Modifier Group'}`
                : modifierGroup?.name || 'New Modifier Group'
            }
            className={styles.title}
          />
          <p className={styles.description}>{isEditMode ? 'Edit a Modifier Group' : 'Create a new Modifier Group'}</p>
        </div>
        <div className={styles.form_container}>
          {isLoading ? (
            <div className={styles.backdrop}>
              <BackdropLoading />
            </div>
          ) : (
            <ModifierGroupForm modifierGroup={modifierGroup} onChange={handleInputChange} errors />
          )}
          <div className={styles.action_buttons}>
            <Button
              className={styles.button}
              variant={'secondary'}
              disabled={isLoading}
              title={'Cancel'}
              onClick={closeModal}
            />
            <Button
              className={styles.button}
              disabled={isLoading}
              title={isEditMode ? 'Save Changes' : 'Add Modifier Group'}
              onClick={() => saveChanges(modifierGroup)}
            />
          </div>
        </div>
      </section>
    </Modal>
  );
}

export default EditModifierGroupModal;
