import axios from 'axios';
import store from '../redux/store';
import { logoutUser, refreshToken } from '../redux/authSlice';
import RequestQueueManager from './request_queue_manager';

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

export const handleApiResponse = async (request) => {
  try {
    const response = await request();
    return response.data;
  } catch (error) {
    console.log(error);
    throw error.response?.data || 'API request failed';
  }
};

export const queueManager = new RequestQueueManager();

api.interceptors.request.use(
  async (config) => {
    if (queueManager.isTokenRefreshing && !config.url.includes('auth/refresh')) {
      try {
        return await queueManager.enqueue(config);
      } catch (error) {
        return Promise.reject(error);
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (!error.response) {
      return Promise.reject(error);
    }

    const { status } = error.response;
    const tokenExpired = error.response.headers?.['x-auth-token-expired'];

    try {
      if ((status === 401 || status === 403) && tokenExpired && !originalRequest._retry) {
        if (queueManager.isTokenRefreshing) {
          try {
            const newConfig = await queueManager.enqueue(originalRequest);

            return await api(newConfig);
          } catch (enqueueError) {
            return Promise.reject(enqueueError);
          }
        }

        originalRequest._retry = true;
        queueManager.setRefreshing(true);

        try {
          const { auth_token, refresh_token } = store.getState().auth.loginState;
          const { auth_token: new_auth_token } = await store
            .dispatch(
              refreshToken({
                auth_token,
                refresh_token,
              }),
            )
            .unwrap();

          queueManager.setRefreshing(false, new_auth_token);

          if (new_auth_token) {
            originalRequest.headers['Authorization'] = `Bearer ${new_auth_token}`;
            return api(originalRequest);
          }

          throw new Error('Token refresh failed');
        } catch (refreshError) {
          queueManager.setRefreshing(false);
          const { auth_token, refresh_token } = store.getState().auth.loginState;
          await store
            .dispatch(
              logoutUser({
                auth_token,
                refresh_token,
              }),
            )
            .unwrap();
          throw refreshError;
        }
      }

      if (status === 401 || status === 403) {
        const { auth_token, refresh_token } = store.getState().auth.loginState;
        await store
          .dispatch(
            logoutUser({
              auth_token,
              refresh_token,
            }),
          )
          .unwrap();
        return Promise.reject(error);
      }

      throw error;
    } catch (interceptorError) {
      return Promise.reject(interceptorError);
    }
  },
);
