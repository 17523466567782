import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  fetchMenuItems,
  createMenuItem,
  removeMenuItem,
  updateExistingMenuItemStatusPOS,
} from '../../../../redux/menuItemsSlice';
import { showModal, hideModal } from '../../../../redux/modalSlice';
import { fetchCategories } from '../../../../redux/categoriesSlice';
import { addNotification } from '../../../../redux/tooltipSlice';

import { useAppContext } from '../../../../context/context';

import PageTitle from '../../../../components/utilities/page-title/page-title';
import Selector from '../../../../components//utilities/selector/selector';
import Search from '../../../../components//utilities/search/search';
import Button from '../../../../components/utilities/button/button';
import Toggler from '../../../../components/utilities/toggler/toggler';
import NoItems from '../../../utilities/no-items/no-items';
import useEdgeClick from '../../../utilities/useEdgeClick';

import MenuItem from './item/item';

import { IoIosArrowDown } from 'react-icons/io';
import Plus from '../../../../assets/icons/plus.svg';
import { ReactComponent as NoItemsIcon } from '../../../../assets/icons/no_menu_items.svg';
import styles from './items.module.scss';

function Items() {
  // Hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useAppContext();

  // Store
  const menu_items = useSelector((state) => state.menu_items.menu_items);
  const categories = useSelector((state) => state.categories.categories);

  const status = useSelector((state) => state.menu_items.status);
  const isLoadingFirstTime = status === 'idle';
  const isLoading = status === 'loading';

  // Local state
  const [filteredMenuItems, setFilteredMenuItems] = useState({}); // Menu items according to the filters
  const [searchTerm, setSearchTerm] = useState(''); // Search term string
  const [selectedCategoryId, setSelectedCategoryId] = useState(0); // Selected category in filters section
  const [toggleStatus, setToggleStatus] = useState(true); // Toggle status for Switcher (showing/hiding items)

  const selectorOptions = useMemo(() => {
    // the list of categories for the component Selector
    return categories.map((item) => ({
      value: item.id,
      label: item.name,
    }));
  }, [categories]);

  // Refs
  const { ref, position, handleClick } = useEdgeClick(110);
  const isDropdownOutOfEdge = position.includes('right');

  // Effects
  useEffect(() => {
    if (token) {
      dispatch(fetchMenuItems({ itemsDetails: {}, token }));
      dispatch(fetchCategories({ token }));
    }
  }, [dispatch, token]);

  useEffect(() => {
    let filtered = menu_items;

    if (selectedCategoryId !== 0) {
      // Filtering by category selector
      filtered = filtered.filter((item) => item.category_ids.includes(selectedCategoryId));
    }

    if (searchTerm !== '') {
      // Filtering by search term
      filtered = filtered.filter((item) => item.name.toLowerCase().includes(searchTerm.toLowerCase()));
    }

    if (filtered) {
      // Filtering by hide/unhide toggling
      const inactiveItems = filtered.filter((item) => item.hide_status === 'UnHide');
      const activeItems = filtered.filter((item) => item.hide_status === 'HideForToday');

      filtered = toggleStatus ? inactiveItems : activeItems;
    }

    setFilteredMenuItems(filtered);
  }, [menu_items, selectedCategoryId, searchTerm, toggleStatus]);

  // Handlers
  const handleAction = (message, status) => {
    // Custom notification handler
    dispatch(addNotification({ message, status }));
  };

  const handleFilterByCategory = (selectedValue) => {
    // Category selector handler
    setSelectedCategoryId(Number(selectedValue));
  };

  const handleSetResult = (term) => {
    // Search term handler
    setSearchTerm(term);
  };

  const handleSearchClean = () => {
    // Clear search term handler
    setSearchTerm('');
  };

  const handleToggleActive = (id, status) => {
    // Toggle item status handler
    dispatch(updateExistingMenuItemStatusPOS({ id: Number(id), status, token }))
      .unwrap()
      .then(() => {
        dispatch(fetchMenuItems({ itemsDetails: {}, token }));
      });
    dispatch(updateExistingMenuItemStatusPOS({ id: Number(id), status, token }))
      .unwrap()
      .then(() => {
        dispatch(fetchMenuItems({ itemsDetails: {}, token }));
      });
  };

  const handleAddNewItem = () => {
    // Add new item handler
    navigate('/menu/items/new');
  };

  const handleCopyItem = async (id) => {
    // Clone item handler
    if (!id || !Array.isArray(filteredMenuItems) || !token) {
      handleAction(!id ? 'Invalid ID' : 'Missing data or token', 'failed');
      return;
    }

    const duplicatedItem = filteredMenuItems.find((item) => item.id === id);

    if (!duplicatedItem) {
      handleAction('No menu item found', 'failed');
      return;
    }

    const newDetails = {
      active: duplicatedItem.active || true,
      name: `${duplicatedItem.name} Copy` || 'Copy',
      cost: duplicatedItem.cost || 0,
      desc: duplicatedItem.desc || null,
      kitchen_name: duplicatedItem.kitchen_name || '',
      price: duplicatedItem.price || 0,
      image: duplicatedItem.image || null,
      price_type: duplicatedItem.price_type || 0,
      product_code: duplicatedItem.product_code || '',
      quantity: duplicatedItem.quantity || 0,
      sku: duplicatedItem.sku || '',
      apply_category_modifier_groups: duplicatedItem.apply_category_modifier_groups || true,
      category_ids: duplicatedItem.category_ids || [],
      tax_ids: duplicatedItem.tax_ids || [],
      modifier_group_ids: duplicatedItem.modifier_group_ids || [],
    };

    try {
      await dispatch(createMenuItem({ newDetails, token })).unwrap();
      handleAction('Item was cloned successfully', 'succeeded');
    } catch {
      handleAction('Failed to clone Item', 'failed');
    }
  };

  const handleEditItem = (id) => {
    // Edit item handler
    navigate(`/menu/items/${id}/edit`);
  };

  const handleShowItem = (id) => {
    navigate(`/menu/items/${id}/details`);
  };

  const handleDeleteItem = (id) => {
    // Delete item handler
    dispatch(
      showModal({
        modalId: 'modal-delete-item',
        data: {
          type: 'confirmation',
          title: 'Delete Confirmation',
          message: 'Are you sure you want to delete this item?',
          actions: [
            {
              title: 'Delete',
              onAction: () => {
                dispatch(removeMenuItem({ id, token }));
                dispatch(hideModal('modal-delete-item'));
              },
            },
            { title: 'Cancel', button_variant: 'secondary', onAction: () => dispatch(hideModal('modal-delete-item')) },
          ],
        },
      }),
    );
  };

  return (
    <div className={styles.items}>
      <div className={styles.header}>
        <div className={styles.title_container}>
          <PageTitle name={'Items'} />
          <div>
            <Button
              className={styles.add_button}
              title={'Add Item'}
              onClick={handleAddNewItem}
              prefixIcon={<img src={Plus} alt="Plus_icon" />}
            />
          </div>
        </div>

        <div className={styles.filters_container}>
          <div className={styles.category_selector}>
            <Selector
              icon={IoIosArrowDown}
              items={selectorOptions}
              additionalItems={[{ label: 'All', value: 0 }]}
              selectedItem={selectedCategoryId}
              onSelectItem={handleFilterByCategory}
            />
          </div>
          <div className={styles.search}>
            <Search
              placeholder="Search..."
              onSearchResults={handleSetResult}
              onClear={handleSearchClean}
              autoFilter={false}
            />
          </div>
          <div className={styles.toggler}>
            <Toggler
              toggle={toggleStatus}
              onToggle={setToggleStatus}
              titles={{ active: 'Shown on POS', inactive: 'Hidden on POS' }}
              toggleWidth={256}
            />
          </div>
        </div>
      </div>

      <div className={styles.list_container} ref={ref} onClick={handleClick}>
        {menu_items?.length > 0 ? (
          filteredMenuItems?.length > 0 ? (
            filteredMenuItems.map((item) => (
              <MenuItem
                key={item.id}
                item_info={item}
                categories={categories}
                onClone={handleCopyItem}
                onShow={handleShowItem}
                onEdit={handleEditItem}
                onDelete={handleDeleteItem}
                onToggleActive={handleToggleActive}
                isLoading={isLoadingFirstTime || isLoading}
                isDropdownOutOfEdge={isDropdownOutOfEdge}
              />
            ))
          ) : (
            <NoItems
              title="There is not items hided on POS"
              description="Change the the Switcher status to set the first one."
              icon={NoItemsIcon}
              isLoading={isLoadingFirstTime || isLoading}
            />
          )
        ) : (
          <NoItems
            title="No items added."
            description="Click on the «+ Add item button to create the first one."
            icon={NoItemsIcon}
            isLoading={isLoadingFirstTime || isLoading}
          />
        )}
      </div>
    </div>
  );
}

export default Items;
