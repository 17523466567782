import Spinner from '../spinner/spinner';

import styles from './no-image.module.scss';

function NoImage({ title = '', description = '', icon: Icon, isLoading }) {
  const NoImagePng = require('../../../assets/icons/no_image_stumb.png');

  return (
    <div className={styles.no_items}>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          {Icon ? <Icon /> :  <img src={NoImagePng} alt="" className={styles.image} />}
          <h5>{title}</h5>
          <h5>{description}</h5>
        </>
      )}
    </div>
  );
}

export default NoImage;
