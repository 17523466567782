import React, { useCallback, useMemo, forwardRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { nanoid } from 'nanoid';

import styles from './input.module.scss';

const checkValueForEmpty = (value) => value === '' || value === undefined || value === null;

const Input = forwardRef(function Input(
  {
    value,
    onChange,
    id,
    name = nanoid(),
    type = 'text',
    placeholder,
    autoComplete,
    step = 1,
    min = 0,
    max,
    label,
    required = false,
    disabled,
    prefixIcon,
    prefixButtonCallback,
    suffixIcon,
    suffixButtonCallback,
    activePlaceholder = false,
    errors = [],
    inputProps,
    className,
    style,
  },
  ref,
) {
  // const inputField = useRef(null);
  const safeValue = value ?? '';

  const handleOnChange = useCallback(
    (e) => {
      onChange(e, type);
    },
    [onChange, type],
  );

  const inputClassName = useMemo(
    () => clsx(styles.field, prefixIcon && styles.with_prefix, suffixIcon && styles.with_suffix),
    [prefixIcon, suffixIcon],
  );

  const renderIcon = (icon, callback, styleClass) => {
    return (
      icon && (
        <div className={styles[styleClass]} onClick={callback}>
          {icon}
        </div>
      )
    );
  };

  return (
    <div className={clsx(styles.wrapper, className)}>
      <div className={styles.container} style={style}>
        {label && (
          <label className={clsx(styles.label, required && styles.asterisk)} htmlFor={name}>
            {label}
          </label>
        )}
        {activePlaceholder && placeholder && (
          <p
            className={clsx(
              checkValueForEmpty(safeValue) ? styles.inner_placeholder : styles.inner_placeholder_active,
              required && styles.asterisk,
            )}
          >
            {placeholder}
          </p>
        )}
        {renderIcon(prefixIcon, prefixButtonCallback, 'prefix_icon')}
        {renderIcon(suffixIcon, suffixButtonCallback, 'suffix_icon')}
        <input
          ref={ref}
          id={String(id ?? name)}
          autoComplete={autoComplete}
          name={name}
          className={inputClassName}
          type={type}
          min={min}
          max={max}
          step={step ?? '0.01'}
          value={safeValue}
          onChange={handleOnChange}
          placeholder={activePlaceholder ? undefined : placeholder}
          disabled={disabled}
          {...inputProps}
        />
      </div>
      {!!errors.length && (
        <div className={styles.error_container}>
          {errors.map((error, index) => (
            <div key={index} className={styles.error}>
              {error}
            </div>
          ))}
        </div>
      )}
    </div>
  );
});

Input.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  prefixIcon: PropTypes.element,
  prefixButtonCallback: PropTypes.func,
  suffixIcon: PropTypes.element,
  suffixButtonCallback: PropTypes.func,
  activePlaceholder: PropTypes.bool,
  inputProps: PropTypes.object,
  className: PropTypes.string,
  style: PropTypes.object,
};

export default Input;
