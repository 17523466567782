import Switch from '../../../../utilities/switch/switch';
import NoItems from '../../../../utilities/no-items/no-items';

import { hexToRgba } from '../../../../utilities/utils';

import { IoCubeOutline } from 'react-icons/io5';

import { ReactComponent as NoImage } from '../../../../../assets/icons/no_image.svg';

import clsx from 'clsx';

import styles from './item-cards.module.scss';

const HIDE_STATUS = {
  0: 'UnHide',
  1: 'HideForToday',
  2: 'HideForIndefinitely',
};

const SkeletonCard = () => {
  return (
    <div className={styles.card_container}>
      <div className={styles.head}>
        <span className={styles.skeleton_box} style={{ width: '80px' }}></span>
        <div className={styles.cost_row}>
          {<span className={styles.skeleton_box} style={{ width: '30px' }}></span>}
          <div className={clsx(styles.tag, styles.skeleton_box)} style={{ width: '30px' }}></div>
        </div>
        <div className={styles.onpos_row}>
          <span className={styles.skeleton_box} style={{ width: '100%' }}></span>
        </div>
      </div>
      <div className={clsx(styles.image, styles.skeleton_box)}></div>
    </div>
  );
};

export const ItemCard = ({
  item,
  onClick = () => {},
  toggleOnPOS = () => {},
  renderComponents = { cost: true, onPosController: true },
  tagLabel,
  tagColor,
  commonIcon: Icon,
}) => {
  return (
    <div className={styles.card_container}>
      <div className={styles.head}>
        <span className={styles.name} title={item.name} onClick={() => onClick(item.id)}>
          {item.name}
        </span>
        <div className={styles.cost_row}>
          {renderComponents.cost && <span>${item?.cost}</span>}
          {tagLabel && (
            <div className={styles.tag} style={{ '--text-color': hexToRgba(tagColor) || '#656565' }}>
              {tagLabel}
            </div>
          )}
        </div>
        {renderComponents.onPosController && (
          <div className={styles.onpos_row}>
            <span>On POS</span>
            <Switch id={item.id} isActive={item.hide_status === HIDE_STATUS[0]} onSwitch={toggleOnPOS} />
          </div>
        )}
      </div>
      <div className={styles.image} onClick={() => onClick(item.id)}>
        {Icon ? (
          <div className={styles.menu_item_icon}>
            <Icon />
          </div>
        ) : item?.image ? (
          <img src={item.image} alt={item.name + ' image'} />
        ) : (
          <NoItems title="Image not added" icon={NoImage} className={styles.no_items} />
        )}
      </div>
    </div>
  );
};

export const ItemCardSection = ({
  isLoading = false,
  length = 0,
  noItemsProps: { title, description } = {},
  children,
}) => {
  if (length > 0 && isLoading) {
    return <>{children}</>;
  }

  if (length < 1 && isLoading) {
    return <SkeletonCard />;
  }

  if (length < 1 && !isLoading) {
    return (
      <div className={styles.no_data}>
        <NoItems
          title={title}
          description={description}
          icon={IoCubeOutline}
          className={styles.no_items}
          iconSize="60px"
          fontSize="1rem"
        />
      </div>
    );
  }

  return children;
};
