import React from 'react';

import clsx from 'clsx';
import styles from './page-title.module.scss';

const PageTitle = ({ name, className, onClick }) => {
  return (
    <h2 className={clsx(styles.title, className)} onClick={onClick}>
      {name}
    </h2>
  );
};

export default PageTitle;
