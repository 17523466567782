import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useAppContext } from '../../../context/context';

import { logoutUser } from '../../../redux/authSlice';

import Header from '../header/header';
import Sidebar from '../sidebar/sidebar';
import MainContent from '../content/content';
import Footer from '../footer/footer';

import styles from './home.module.scss';

function Home() {
  const { token, refreshToken } = useAppContext();

  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [activeParent, setActiveParent] = useState(null);
  const [isMobile, setIsMobile] = useState(() => window.innerWidth < 768);
  const dispatch = useDispatch();

  const userName = useSelector((state) => state.auth.loginState.user_name);
  const locationName = useSelector((state) => state.auth.loginState.location_name);

  const handleResize = useCallback(() => {
    const newIsMobile = window.innerWidth <= 768;
    setIsMobile(newIsMobile);
    setIsSidebarOpen(!newIsMobile);
  }, []);

  const toggleSidebar = () => {
    // if (activeParent && !isMobile) {
    //   setActiveParent(null);
    // } else {
    //   setIsSidebarOpen(!isSidebarOpen);
    // }
    setIsSidebarOpen(!isSidebarOpen);
  };

  const onLogout = () => {
    dispatch(logoutUser({ auth_token: token.replace('Bearer ', ''), refresh_token: refreshToken }));
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  return (
    <div className={styles.main_container}>
      <Header
        userName={userName}
        onMenuToggle={toggleSidebar}
        isSidebarOpen={isSidebarOpen}
        locationName={locationName}
        isMobile={isMobile}
        setIsSidebarOpen={setIsSidebarOpen}
      />
      <div className={styles.wrap}>
        <Sidebar
          isOpen={isSidebarOpen}
          onLogout={onLogout}
          activeParent={activeParent}
          setActiveParent={setActiveParent}
          isMobile={isMobile}
          isSidebarOpen={isSidebarOpen}
          setIsSidebarOpen={setIsSidebarOpen}
        />
        <MainContent isSidebarOpen={isSidebarOpen} isMobile={isMobile} />
      </div>
      <Footer termsLink={'google.com'} privacyPolicyLink={'youtube.com'} />
    </div>
  );
}

export default Home;
