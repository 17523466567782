export default class RequestQueueManager {
  constructor() {
    this.isTokenRefreshing = false;
    this.processing = false;
    this.queue = [];
  }

  enqueue(config) {
    return new Promise((resolve, reject) => {
      this.queue.push({
        config,
        resolve,
        reject,
      });
    });
  }

  async processQueue(withToken) {
    if (this.processing) {
      return;
    }

    this.processing = true;

    while (this.queue.length > 0) {
      const { config, resolve, reject } = this.queue[0];

      try {
        if (withToken) {
          config.headers['Authorization'] = `Bearer ${withToken}`;
        }

        resolve(config);
      } catch (error) {
        reject(error);
      }

      this.queue.shift();
    }

    this.processing = false;
  }

  setRefreshing(value, withToken) {
    this.isTokenRefreshing = value;
    if (!value) {
      this.processQueue(withToken);
    }
  }
}
