import React, { useState, useMemo, useCallback, useRef, useEffect } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { nanoid } from 'nanoid';

import BackdropLoading from '../backdrop-loading/backdrop-loading';
import ListStateRenderer from './list-state-renderer';

import { GrDocumentMissing } from 'react-icons/gr';
import { GiHamburgerMenu } from 'react-icons/gi';

import clsx from 'clsx';
import styles from './list.module.scss';

import { compareArraysOfObjectsById } from '../utils';
import useDebounce from '../useDebounce';

const ITEM_TYPE = 'ROW';

function sortTableItems(items, key, order = 'asc') {
  return [...items].sort((a, b) => {
    if (a[key] < b[key]) {
      return order === 'asc' ? -1 : 1;
    }
    if (a[key] > b[key]) {
      return order === 'asc' ? 1 : -1;
    }
    return 0;
  });
}

const checkForDragRequirements = (arr = []) =>
  arr.every((item) => 'id' in item && 'sort' in item && item.id != null && item.sort != null);

const TableRow = React.memo(
  ({ item, id, index, columnConfig, rowActions, rowClassName, moveRow, onDragged, isDragable }) => {
    const ref = useRef(null);

    const [, drop] = useDrop({
      accept: ITEM_TYPE,
      hover(item, monitor) {
        if (!ref.current) {
          return;
        }

        const dragIndex = item.index;
        const hoverIndex = index;
        const targetId = id;

        if (dragIndex === hoverIndex) {
          return;
        }

        const hoverBoundingRect = ref.current?.getBoundingClientRect();

        const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2 - 4; //Added additional number (4) for more correct hover positioning
        const clientOffset = monitor.getClientOffset();
        const hoverClientY = clientOffset.y - hoverBoundingRect.top;

        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
          return;
        }

        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
          return;
        }

        moveRow(dragIndex, hoverIndex);

        item.index = hoverIndex;
        item.targetId = targetId;
      },
    });

    const [{ isDragging }, drag, preview] = useDrag({
      type: ITEM_TYPE,
      item: { sourceId: id, index },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      end: (item, monitor) => {
        if (!monitor.didDrop()) {
          return;
        }
        !!onDragged && onDragged(item);
      },
    });

    if (isDragable) {
      drop(preview(ref));
    }

    return (
      <div
        ref={ref}
        className={clsx(styles.row, styles.body, rowClassName)}
        role="row"
        data-testid={`dragged_row_${id}`}
        style={{ opacity: isDragging ? 0 : 1 }}
      >
        {isDragable && (
          <div className={clsx(styles.cell, styles.drag_cell)}>
            <div ref={drag} className={styles.drag_icon} data-testid={`drag_button_${id}`}>
              <GiHamburgerMenu />
            </div>
          </div>
        )}
        {columnConfig.map((column) => (
          <div
            key={column.key}
            className={clsx(styles.cell, column.cellClassName)}
            data-label={column.header}
            role="cell"
            style={{ '--max-cell-width': `${75 / columnConfig.length}vw` }}
          >
            {column.render ? column.render(item) : item[column.key]}
          </div>
        ))}
        {rowActions && (
          <div className={styles.cell} role="cell" data-label={'Actions'}>
            <div className={styles.control_icons}>
              {rowActions.map((action, index) => (
                <button
                  key={index}
                  onClick={() => action.onClick(item.id)}
                  aria-label={action.label}
                  className={clsx(styles.row_actions_button, action.className)}
                >
                  {action.icon}
                </button>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  },
);

/**
 * List component
 * @component
 *
 * @param {Object} props - Component props
 * @param {Array} props.data - An array of data to be displayed in the table (shoud be an array of the objects with id )
 * @param {Object} props.columnConfig - The configuration of the table columns. Contains headers and keys for displaying data and can render element instead value
 * @param {Array} props.rowActions - An array of actions that can be applied to rows (for example, edit or delete)
 * @param {boolean} props.isLoading - Indicates the state of data loading (for Backdrop)
 * @param {boolean} [props.isFailed] - Required for correctly work of drag-n-drop to move the item back when response is failed
 * @param {boolean} [props.isDragable] - Whether fields can be reordered by dragging
 * @param {Function} props.onDragged - Callback function called when dragged fields dropped
 * @param {Function} props.onChange - Callback function for passing state to the parent component
 *
 * @returns {React.ReactElement} A table component with head and rows (displayed like table using divs)
 *
 * @example
 *  <List
 *    data={options}
 *    columnConfig={[{key: 'name', header: 'Name'}, {key: 'activity', header: 'Activity', render: renderActivity, cellClassName: styles.cell}]}
 *    rowActions={[{ icon: <IconComponent />, onClick: (id) => console.log('id - id of the item', id), label: 'Edit element', className: class_name }}
 *    isLoading={true}
 *    isDragable={true}
 *    onDragged={(item) => console.log('Item as {id: "Id of the moved item", index: "Index in the array where the element was moved to"}:', item)}
 *    onChange={(data) => console.log('data - sortedData')}
 *  />
 */

const List = ({
  data = [],
  columnConfig,
  isLoading,
  isFailed,
  rowActions,
  isDragable,
  onDragged,
  onChange,
  noDataMessage,
  isWithHeader = true,
  isNoDataDisplayed = true,
  passStateOutsideWhenDrag = false,
  className,
  rowClassName,
  overrideActionHeadName = false,
  style,
}) => {
  const isListDragable = useMemo(() => checkForDragRequirements(data) && isDragable, [data, isDragable]);
  const sortData = useCallback((arr) => (isListDragable ? sortTableItems(arr, 'sort') : arr), [isListDragable]);

  const [dataList, setDataList] = useState(sortData(data));

  const debouncedAddress = useDebounce(dataList, 100);

  useEffect(() => {
    !compareArraysOfObjectsById(data, dataList) && setDataList(sortData(data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, sortData, isFailed]);

  useEffect(() => {
    if (passStateOutsideWhenDrag) {
      onChange?.(dataList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedAddress, onChange]);

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      setDataList((prevRows) => {
        const updatedRows = prevRows.map((row, index) => {
          if (index === dragIndex) {
            return { ...row, sort: prevRows[hoverIndex].sort };
          } else if (index === hoverIndex) {
            return { ...row, sort: prevRows[dragIndex].sort };
          }
          return row;
        });
        return sortData(updatedRows);
      });
    },
    [sortData],
  );

  return (
    <DndProvider backend={HTML5Backend}>
      <ListStateRenderer isLoading={isLoading} isNoDataDisplayed={isNoDataDisplayed} length={dataList?.length}>
        <div className={clsx(styles.container, className)} role="table" aria-label="Data list" style={style}>
          <div
            className={clsx(styles.table, isLoading ? styles.blured : null, noDataMessage ? styles.table_simple : null)}
          >
            {isWithHeader && (
              <div className={clsx(styles.header, styles.row, rowClassName)} role="row">
                {isListDragable && (
                  <div className={clsx(styles.cell, styles.head_drag)} data-label={'column.header'} role="cell"></div>
                )}
                {columnConfig.map((column) => (
                  <div key={column.key} className={clsx(styles.cell, column.className)} role="columnheader">
                    {column.header}
                  </div>
                ))}
                {rowActions && (
                  <div className={styles.cell} role="columnheader">
                    {overrideActionHeadName || 'Actions'}
                  </div>
                )}
              </div>
            )}
            {dataList.length > 0 &&
              dataList.map((item, index) => (
                <TableRow
                  key={item?.id || nanoid()}
                  id={item?.id}
                  item={item}
                  index={index}
                  columnConfig={columnConfig}
                  rowActions={rowActions}
                  rowClassName={rowClassName}
                  isDragable={isListDragable}
                  onDragged={onDragged}
                  moveRow={moveRow}
                />
              ))}
          </div>

          {/* {!dataList.length &&
            !isLoading &&
            isNoDataBlock &&
            (noDataMessage ? (
              <div className={styles.no_data_simple}>
                <span className={styles.message}>{noDataMessage}</span>
              </div>
            ) : (
              <div className={styles.no_data}>
                <GrDocumentMissing className={styles.icon} />
                <span>No data available</span>
              </div>
            ))} */}
        </div>
      </ListStateRenderer>
    </DndProvider>
  );
};

export default List;
