import React, { useState } from 'react';

import { DashboardMetricType } from './metricLibrary';

import infoIcon from '../../../../assets/img/ic_info.svg';
import styles from './metric.module.scss';

function Metric({ item }) {
  const [showTooltip, setShowTooltip] = useState(false);

  const formattedItem = { ...item, ...DashboardMetricType[item?.type] };
  const formattedValue = `$${item.value.toFixed(2)}`;

  return (
    <div className={styles.metric}>
      <div className={styles.icon}>{formattedItem?.Icon && <formattedItem.Icon />}</div>
      <div className={styles.value}>{formattedValue}</div>
      <div className={styles.title_wrapper}>
        <div className={styles.title}>{formattedItem.name}</div>
        {formattedItem.info && (
          <div
            className={styles.info_icon}
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
          >
            <img src={infoIcon} alt="info_icon" />
            {showTooltip && <div className={styles.info_tooltip}>{formattedItem.info}</div>}
          </div>
        )}
      </div>
    </div>
  );
}

export default Metric;
