import { useState, useRef, useCallback } from 'react';

const useEdgeClick = (threshold = 20, externalRef = null) => {
  const [position, setPosition] = useState('center');

  const ref = useRef(externalRef);

  const handleClick = useCallback(
    (event) => {
      if (!ref.current) return;
      const rect = ref.current.getBoundingClientRect();

      // Calculate the distance from the edges
      const distanceFromLeft = event.clientX - rect.left;
      const distanceFromRight = rect.right - event.clientX;
      const distanceFromTop = event.clientY - rect.top;
      const distanceFromBottom = rect.bottom - event.clientY;

      // Determine the position of the click
      const determinePosition = (
        distanceFromLeft,
        distanceFromRight,
        distanceFromTop,
        distanceFromBottom,
        threshold,
      ) => {
        let position = '';

        if (distanceFromLeft <= threshold) position += 'left ';
        if (distanceFromRight <= threshold) position += 'right ';
        if (distanceFromTop <= threshold) position += 'top ';
        if (distanceFromBottom <= threshold) position += 'bottom ';

        return position.trim() || 'center';
      };

      setPosition(
        determinePosition(distanceFromLeft, distanceFromRight, distanceFromTop, distanceFromBottom, threshold),
      );
    },
    [threshold],
  );

  return { ref, position, handleClick };
};

export default useEdgeClick;
