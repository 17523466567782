import React, { useState, useEffect, useCallback } from 'react';

import clsx from 'clsx';

import { FaPlus } from 'react-icons/fa';

import { convertValueByType } from '../../../../utilities/utils';
import Input from '../../../../utilities/input/input';
import DynamicFormFields from '../../../../utilities/dynamic-form-fields/dynamic-form-fields ';
import useDebounce from '../../../../utilities/useDebounce';

import ModifierFormSection from './modifier-form-section';

import styles from './modifier-group-form.module.scss';

const ModifierGroupForm = ({ modifierGroup: incomeModifierGroup = [], onChange, errors }) => {
  const [modifierGroup, setModifier] = useState(incomeModifierGroup);

  const debouncedAddress = useDebounce(modifierGroup, 200);

  const maxFields = () => {
    if (modifierGroup?.min >= 0 && modifierGroup?.max) {
      return modifierGroup?.max;
    }

    if (modifierGroup?.min >= 0) {
      return modifierGroup?.min > 0 ? modifierGroup?.min : 0;
    }

    return 0;
  };

  useEffect(() => {
    onChange(modifierGroup);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedAddress]);

  const handleInputChange = (e, type) => {
    const { name, value } = e.target;
    setModifier((prev) => ({
      ...prev,
      [name]: convertValueByType(value, type),
    }));
  };

  const handleModifiersChange = useCallback((modifiers = []) => {
    handleInputChange({
      target: {
        name: 'modifiers',
        value: modifiers,
      },
    });
  }, []);

  return (
    <div className={styles.section}>
      <div className={styles.modifier_group_fields}>
        <div className={clsx(styles.field, styles.name)}>
          <Input
            type="text"
            name="name"
            label="Modifier Group Name"
            min={0}
            placeholder="Modifier Group Name"
            value={modifierGroup?.name}
            onChange={handleInputChange}
          />
        </div>
        <div className={clsx(styles.field, styles.min_max)}>
          <Input
            type="number"
            name="min"
            label="min"
            value={(modifierGroup?.min ?? modifierGroup?.modifiers?.length > 0) ? 1 : 0}
            onChange={handleInputChange}
          />
        </div>
        <div className={clsx(styles.field, styles.min_max)}>
          <Input
            type="number"
            name="max"
            label="max"
            min={1}
            value={modifierGroup?.max ?? 1}
            onChange={handleInputChange}
          />
        </div>
      </div>
      <DynamicFormFields
        value={modifierGroup.modifiers}
        fieldItem={ModifierFormSection}
        addButtonTitle={'Add Modifier'}
        onChange={handleModifiersChange}
        containerClass={styles.dynamic_form_container}
        formClasses={{
          buttonClassName: styles.delete_button,
          constainerClassName: styles.dynamic_row_container,
        }}
        addButtonClasses={{
          prefixIcon: <FaPlus />,
          className: styles.add_dynamic_field_button,
          variant: 'transparent',
        }}
        errors={errors?.modifiers}
      />
    </div>
  );
};

export default ModifierGroupForm;
