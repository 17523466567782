import { api, handleApiResponse } from './base';

export const getItems = (itemsDetails, token) => {
  const { category_ids } = itemsDetails || {};

  const params = new URLSearchParams();
  if (category_ids) params.append('category_ids', category_ids);

  const url = `items?${params.toString() ? `${params.toString()}` : ''}&per_page=999`; // TODO: Change to real pagination

  return handleApiResponse(() =>
    api.get(url, {
      headers: {
        Authorization: token,
      },
    }),
  );
};

export const addItem = (newDetails, token) =>
  handleApiResponse(() =>
    api.post('items', newDetails, {
      headers: {
        Authorization: token,
      },
    }),
  );

export const getItem = (id, token) =>
  handleApiResponse(() =>
    api.get(`items/${id}`, {
      headers: {
        Authorization: token,
      },
    }),
  );

export const updateItem = (id, updatedDetails, token) =>
  handleApiResponse(() =>
    api.put(`items/${id}`, updatedDetails, {
      headers: {
        Authorization: token,
      },
    }),
  );

export const deleteItem = (id, token) =>
  handleApiResponse(() =>
    api.delete(`items/${id}`, {
      headers: {
        Authorization: token,
      },
    }),
  );

// Maybe we shoold use an empty {} here like a second parameter
export const updateItemStatusPOS = (id, status, token) =>
  handleApiResponse(() =>
    api.put(
      `items/${id}/active_pos?hide_status=${status}`,
      {},
      {
        headers: {
          Authorization: token,
        },
      },
    ),
  );

// I am not sure about using an empty {} here
export const updateItemStatusWebsite = (id, status, token) =>
  handleApiResponse(() =>
    api.put(
      `items/${id}/active_web_site?website_hide_status=${status}`,
      {},
      {
        headers: {
          Authorization: token,
        },
      },
    ),
  );

export const getInventoriesCount = (token) =>
  handleApiResponse(() =>
    api.get('items/inventories_count', {
      headers: {
        Authorization: token,
      },
    }),
  );
