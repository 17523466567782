import { useState, useEffect, useRef } from 'react';
import clsx from 'clsx';

import styles from './modifier-groups-cards.module.scss';

const ModifierGroupsCard = ({ modifierGroup, onEdit, onDelete, isDropdownOutOfEdge }) => {
  const [activeDropdownId, setActiveDropdownId] = useState(null);
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        setActiveDropdownId(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleActionClick = (action, id) => {
    setActiveDropdownId(null);
    if (action === 'edit') onEdit(id);
    if (action === 'delete') onDelete(id);
  };

  const toggleDropdown = (id) => {
    setActiveDropdownId((prev) => (prev === id ? null : id));
  };

  return (
    <div className={styles.modifier_card}>
      <div className={styles.name_and_button}>
        <p className={styles.modifiers_group_name} onClick={() => handleActionClick('edit', modifierGroup.id)}>
          {modifierGroup?.name}
        </p>
        <div className={styles.ellipsis_button} onClick={() => toggleDropdown(modifierGroup.id)} ref={buttonRef}>
          <button className={styles.ellipsis_icon}></button>
          {activeDropdownId === modifierGroup.id && (
            <ul className={clsx(styles.dropdown, { [styles.out_of_edge]: isDropdownOutOfEdge })} ref={dropdownRef}>
              <li onClick={() => handleActionClick('edit', modifierGroup.id)}>Edit</li>
              <li onClick={() => handleActionClick('delete', modifierGroup.id)}>Delete</li>
            </ul>
          )}
        </div>
      </div>
      <p className={styles.items_counter}>{`${modifierGroup?.item_ids.length || 0} items`}</p>
    </div>
  );
};

export default ModifierGroupsCard;
