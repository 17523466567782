import styles from './three-dots-loading.module.scss';

const ThreeDotsLoading = () => {
  return (
    <div className={styles.container} data-title="dot-flashing">
      <div className={styles.dot_flashing}></div>
    </div>
  );
};

export default ThreeDotsLoading;
