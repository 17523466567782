import { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useAppContext } from '../../../../../context/context';

import {
  fetchModifier,
  addModifierGroup,
  updateModifierGroup,
  removeModifierGroup,
} from '../../../../../redux/modifierSetsSlice';

import { addNotification } from '../../../../../redux/tooltipSlice';
import { showModal, hideModal } from '../../../../../redux/modalSlice';

import { isAnArray, isObjectEmpty, isArrayEmpty } from '../../../../utilities/utils';

import useFormValidation from '../../../../utilities/useFormValidation';
import { registrationSchema } from '../validation';

const useModifierGroupManager = ({
  modifierGroups = [],
  currentModifierGroup = {},
  status,
  urlParams = { searchParams: {}, setSearchParams: () => {} },
}) => {
  const dispatch = useDispatch();
  const { token } = useAppContext();

  const editId = urlParams.searchParams?.get('editId');

  const initialState = useMemo(
    () => ({
      modalState: {
        isModalOpen: !!editId || false,
        isEditMode: !!editId || false,
      },
      currentModifierId: editId || null,
      modifierGroup: { status: 'idle' },
    }),
    [editId],
  );

  const [state, setState] = useState(initialState);
  const { errors, validate, resetErrors } = useFormValidation(registrationSchema);

  const isCurrentModifierGroupLoading = status || !!state.modifierGroup?.status;

  useEffect(() => {
    if (state.currentModifierId) {
      dispatch(fetchModifier({ id: state.currentModifierId, token }));
    }
  }, [dispatch, state.currentModifierId, token]);

  useEffect(() => {
    if (!isObjectEmpty(currentModifierGroup)) {
      setState((prev) => ({ ...prev, modifierGroup: currentModifierGroup }));
    }
  }, [currentModifierGroup]);

  const handleAction = useCallback(
    (message, status) => {
      dispatch(addNotification({ message, status }));
    },
    [dispatch],
  );

  const getModifierGroup = useCallback(
    (modifierGroups, id) => {
      if (!isObjectEmpty(state.modifierGroup) && isArrayEmpty(modifierGroups)) {
        return state.modifierGroup;
      }

      if (!id || !isAnArray(modifierGroups)) {
        handleAction('Invalid ID or Modifier Groups is not an array', 'failed');
        return;
      }

      const modifierGroup = modifierGroups.find((m) => m.id === parseFloat(id));

      if (!modifierGroup) {
        handleAction('No Modifier Group found', 'failed');
        return;
      }

      return modifierGroup;
    },
    [handleAction, state.modifierGroup],
  );

  // Modal state handlers

  const handleShowEditModal = (id) => {
    setState({
      ...initialState,
      modalState: {
        isModalOpen: true,
        isEditMode: true,
      },
      currentModifierId: id,
    });
  };

  const handleShowAddModal = () => {
    setState({
      ...initialState,
      modalState: {
        isModalOpen: true,
      },
      modifierGroup: {},
    });
  };

  const handleModalClose = useCallback(() => {
    setState(() => ({
      ...initialState,
      modalState: {
        isModalOpen: false,
        isEditMode: false,
      },
      currentModifierId: null,
    }));
    urlParams.setSearchParams({});
    resetErrors();
  }, [initialState, resetErrors, urlParams]);

  // Modifier groups state handlers
  const handleAddNewModifierGroup = useCallback(
    async (newModifierGroup) => {
      const newModifierGroupDetails = {
        name: newModifierGroup?.name ?? '',
        min: newModifierGroup?.min ?? 0,
        max: newModifierGroup?.max ?? 0,
        modifiers: newModifierGroup?.modifiers ?? [],
      };

      if (validate(newModifierGroupDetails, 'new')) {
        try {
          await dispatch(addModifierGroup({ newModifierGroupDetails, token })).unwrap();
          handleModalClose();
        } catch {}
      }
    },
    [dispatch, handleModalClose, token, validate],
  );

  const handleCopyModifierGroup = useCallback(
    async (id) => {
      const duplicatedModifierGroup = getModifierGroup(modifierGroups, id);

      const newModifierGroupDetails = {
        name: duplicatedModifierGroup?.name ?? '',
        min: duplicatedModifierGroup?.min ?? 0,
        max: duplicatedModifierGroup?.max ?? 0,
        modifiers: duplicatedModifierGroup?.modifiers ?? [],
      };

      try {
        await dispatch(addModifierGroup({ newModifierGroupDetails, token })).unwrap();
        handleModalClose();
      } catch {}
    },
    [dispatch, getModifierGroup, handleModalClose, modifierGroups, token],
  );

  const handleUpdateModifierGroup = useCallback(
    async (updatedModifierGroup) => {
      const updatedModifierGroupDetails = {
        name: updatedModifierGroup?.name ?? '',
        min: updatedModifierGroup?.min ?? 0,
        max: updatedModifierGroup?.max ?? 0,
        modifiers: updatedModifierGroup?.modifiers ?? [],
      };

      if (validate(updatedModifierGroupDetails, 'edit')) {
        try {
          await dispatch(
            updateModifierGroup({ id: updatedModifierGroup?.id, updatedModifierGroupDetails, token }),
          ).unwrap();
          handleModalClose();
        } catch {}
      }
    },
    [dispatch, handleModalClose, token, validate],
  );

  const handleSaveModifierGroup = useCallback(
    async (updatedModifierGroup) => {
      try {
        state.modalState.isEditMode
          ? handleUpdateModifierGroup(updatedModifierGroup)
          : handleAddNewModifierGroup(updatedModifierGroup);

        handleModalClose();
      } catch (error) {
        console.error('Failed to save Modifier Group:', error);
      }
    },
    [handleAddNewModifierGroup, handleModalClose, handleUpdateModifierGroup, state.modalState.isEditMode],
  );

  const handleDeleteModifierGroup = useCallback(
    (id) => {
      dispatch(
        showModal({
          modalId: 'modal-delete-modifier-group',
          data: {
            type: 'confirmation',
            title: 'Delete Confirmation',
            message: 'Are you sure you want to delete this modifier group?',
            actions: [
              {
                title: 'Delete',
                onAction: () => {
                  dispatch(removeModifierGroup({ id, token }));
                  dispatch(hideModal('modal-delete-modifier-group'));
                },
              },
              {
                title: 'Cancel',
                button_variant: 'secondary',
                onAction: () => dispatch(hideModal('modal-delete-modifier-group')),
              },
            ],
          },
        }),
      );
    },
    [dispatch, token],
  );

  return {
    modifierGroup: state.modifierGroup,
    setModifierGroup: setState,
    isModalOpen: state.modalState.isModalOpen,
    isEditMode: state.modalState.isEditMode,
    isCurrentModifierGroupLoading,
    handleSaveModifierGroup,
    handleCopyModifierGroup,
    handleDeleteModifierGroup,
    handleShowAddModal,
    handleShowEditModal,
    handleModalClose,
    errors,
  };
};

export default useModifierGroupManager;
