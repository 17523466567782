import { api, handleApiResponse } from './base';

export const getCategories = (categoryDetails, token) => {
  const { category_ids, item_ids, search } = categoryDetails || {};

  const params = new URLSearchParams();
  // if (category_ids) params.append('category_ids', category_ids);
  // if (item_ids) params.append('item_ids', item_ids);
  if (search) params.append('search', search);

  const url = `categories${params.toString() ? `?${params.toString()}` : ''}`;

  return handleApiResponse(() =>
    api.get(url, {
      headers: {
        Authorization: token,
      },
    }),
  );
};

export const addCategory = (newDetails, token) =>
  handleApiResponse(() =>
    api.post('categories', newDetails, {
      headers: {
        Authorization: token,
      },
    }),
  );

export const getCategory = (id, token) =>
  handleApiResponse(() =>
    api.get(`categories/${id}`, {
      headers: {
        Authorization: token,
      },
    }),
  );

export const updateCategory = (id, updatedDetails, token) =>
  handleApiResponse(() =>
    api.put(`categories/${id}`, updatedDetails, {
      headers: {
        Authorization: token,
      },
    }),
  );

export const deleteCategory = (id, token) =>
  handleApiResponse(() =>
    api.delete(`categories/${id}`, {
      headers: {
        Authorization: token,
      },
    }),
  );

export const updateCategoryOrder = (id, updatedDetails, token) => {
  return handleApiResponse(() =>
    api.put(
      `categories/${id}/reorder?old_position=${updatedDetails.old_position}&new_position=${updatedDetails.new_position}`,
      {},
      {
        headers: {
          Authorization: token,
        },
      },
    ),
  );
};

export const updateCategoryStatus = (id, is_active, token) =>
  handleApiResponse(() =>
    api.put(
      `categories/${id}/active?is_active=${is_active}`,
      {},
      {
        headers: {
          Authorization: token,
        },
      },
    ),
  );
