import { api } from './base';

const handleApiResponse = async (request) => {
  try {
    const response = await request();
    return response.data;
  } catch (error) {
    throw error.response?.data || 'API request failed';
  }
};

export const getModifierGroups = (modifierDetails, token) => {
  const { category_ids, item_ids, search } = modifierDetails || {};

  const params = new URLSearchParams();
  if (category_ids) params.append('category_ids', category_ids);
  if (item_ids) params.append('item_ids', item_ids);
  if (search) params.append('search', search);

  const url = `modifier_groups${params.toString() ? `?${params.toString()}` : ''}`;

  return handleApiResponse(() =>
    api.get(url, {
      headers: {
        Authorization: token,
      },
    }),
  );
};

export const getModifierGroup = (id, token) =>
  handleApiResponse(() =>
    api.get(`modifier_groups/${id}`, {
      headers: {
        Authorization: token,
      },
    }),
  );

export const createModifierGroup = (newModifierDetails, token) =>
  handleApiResponse(() =>
    api.post('modifier_groups', newModifierDetails, {
      headers: {
        Authorization: token,
      },
    }),
  );

export const updateExistingModifierGroup = (id, updatedDetails, token) =>
  handleApiResponse(() =>
    api.put(`modifier_groups/${id}`, updatedDetails, {
      headers: {
        Authorization: token,
      },
    }),
  );

export const deleteModifierGroup = (id, token) =>
  handleApiResponse(() =>
    api.delete(`modifier_groups/${id}`, {
      headers: {
        Authorization: token,
      },
    }),
  );

// TODO: We do not have this endpoint anymore
export const reorderModifierSet = (id, updatedDetails, token) => {
  return handleApiResponse(() =>
    api.put(
      `modifier_groups/${id}/reorder?old_position=${updatedDetails.old_position}&new_position=${updatedDetails.new_position}`,
      {},
      {
        headers: {
          Authorization: token,
        },
      },
    ),
  );
};
