import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchInventoriesCount } from './inventoriesSlice';
import { addNotification } from './tooltipSlice';

import {
  getModifierGroups,
  getModifierGroup,
  createModifierGroup,
  updateExistingModifierGroup,
  deleteModifierGroup,
  reorderModifierSet,
} from '../api/modifier_sets';

// Thunks
export const fetchModifiers = createAsyncThunk('modifierSets/fetchModifiers', async ({ modifierDetails, token }) => {
  const response = await getModifierGroups(modifierDetails, token);
  return response.data;
});

export const fetchModifier = createAsyncThunk('modifierSets/fetchModifier', async ({ id, token }) => {
  const response = await getModifierGroup(id, token);
  return response.data;
});

export const addModifierGroup = createAsyncThunk(
  'modifierSets/addModifierGroup',
  async ({ newModifierGroupDetails, token }, { dispatch }) => {
    try {
      const response = await createModifierGroup(newModifierGroupDetails, token);
      dispatch(
        addNotification({
          message: 'Modifier Group successfully created',
          status: 'succeeded',
        }),
      );
      dispatch(fetchInventoriesCount(token));
      return response.data;
    } catch (error) {
      dispatch(
        addNotification({
          message: `Failed to create Modifier Group, ${error?.message}`,
          status: 'failed',
        }),
      );
      throw error;
    }
  },
);

export const updateModifierGroup = createAsyncThunk(
  'modifierSets/updateModifierGroup',
  async ({ id, updatedModifierGroupDetails, token }, { dispatch }) => {
    try {
      await updateExistingModifierGroup(id, updatedModifierGroupDetails, token);
      dispatch(
        addNotification({
          message: 'Modifier Group successfully updated',
          status: 'succeeded',
        }),
      );
      return { id, updatedModifierGroupDetails };
    } catch (error) {
      dispatch(
        addNotification({
          message: `Failed to update Modifier Group, ${error?.message}`,
          status: 'failed',
        }),
      );
      throw error;
    }
  },
);

export const removeModifierGroup = createAsyncThunk(
  'modifierSets/removeModifierGroup',
  async ({ id, token }, { dispatch }) => {
    try {
      await deleteModifierGroup(id, token);
      dispatch(
        addNotification({
          message: 'Modifier Group successfully deleted',
          status: 'succeeded',
        }),
      );
      dispatch(fetchInventoriesCount(token));
      return id;
    } catch (error) {
      dispatch(
        addNotification({
          message: `Failed to delete Modifier Group, ${error?.message}`,
          status: 'failed',
        }),
      );
      throw error;
    }
  },
);

export const reorderModifierGroup = createAsyncThunk(
  'modifierSets/reorderModifierGroup',
  async ({ id, updatedDetails, token }, { dispatch }) => {
    try {
      await reorderModifierSet(id, updatedDetails, token);
      dispatch(fetchModifiers({ token }));
      dispatch(addNotification({ message: 'Modifier was changed successfully reordered', status: 'succeeded' }));
      return updatedDetails;
    } catch (error) {
      dispatch(
        addNotification({ message: `Failed to reorder modifier: ${error?.message || error}`, status: 'failed' }),
      );
      throw error;
    }
  },
);

const initialState = {
  modifierSets: [],
  currentmodifierGroup: {},
  queryDetails: {},
  status: 'idle',
  error: null,
};

const modifierSetsSlice = createSlice({
  name: 'modifierSets',
  initialState,
  reducers: {
    setQueryDetails(state, action) {
      state.queryDetails = {
        ...state.queryDetails,
        ...action.payload,
      };
    },
    clearModifierGroups: (state) => {
      state.modifierSets = [];
      state.currentmodifierGroup = {};
      state.status = 'idle';
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    const setLoading = (state) => {
      state.status = 'loading';
    };

    const setSucceeded = (state) => {
      state.status = 'succeeded';
    };

    const setFailed = (state, action) => {
      state.status = 'failed';
      state.error = action?.error.message;
    };

    builder
      .addCase(fetchModifiers.pending, setLoading)
      .addCase(fetchModifiers.fulfilled, (state, action) => {
        setSucceeded(state);
        state.modifierSets = action.payload;
      })
      .addCase(fetchModifiers.rejected, setFailed)

      .addCase(fetchModifier.pending, setLoading)
      .addCase(fetchModifier.fulfilled, (state, action) => {
        setSucceeded(state);
        state.currentmodifierGroup = action.payload;
      })
      .addCase(fetchModifier.rejected, setFailed)

      .addCase(addModifierGroup.pending, setLoading)
      .addCase(addModifierGroup.fulfilled, (state, action) => {
        setSucceeded(state);
        state.modifierSets.push(action.payload);
      })
      .addCase(addModifierGroup.rejected, setFailed)

      .addCase(updateModifierGroup.pending, setLoading)
      .addCase(updateModifierGroup.fulfilled, (state, action) => {
        setSucceeded(state);
        const { updatedModifierGroupDetails } = action.payload;

        const index = state.modifierSets.findIndex((option) => option.id === updatedModifierGroupDetails.id);

        if (index !== -1) {
          state.modifierSets[index] = updatedModifierGroupDetails;
        }
      })
      .addCase(updateModifierGroup.rejected, setFailed)

      .addCase(removeModifierGroup.fulfilled, (state, action) => {
        setSucceeded(state);
        const id = action.payload;
        state.modifierSets = state.modifierSets.filter((option) => option.id !== id);
      })
      .addCase(removeModifierGroup.pending, setLoading)
      .addCase(removeModifierGroup.rejected, setFailed)

      .addCase(reorderModifierGroup.pending, setLoading)
      .addCase(reorderModifierGroup.fulfilled, setSucceeded)
      .addCase(reorderModifierGroup.rejected, setFailed);
  },
});

export const { setQueryDetails, clearModifierGroups } = modifierSetsSlice.actions;
export default modifierSetsSlice.reducer;
