import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { fetchCategories, setQueryDetails } from '../../../../redux/categoriesSlice';

import { useAppContext } from '../../../../context/context';

import PageTitle from '../../../../components/utilities/page-title/page-title';
import Search from '../../../../components//utilities/search/search';
import Button from '../../../../components/utilities/button/button';

import CategoryCards from './components/category-cards';
import CategoryCardsSection from './components/category-card-section';
import EditCategoryModal from './modals/edit-category-modal';

import Plus from '../../../../assets/icons/plus.svg';
import { ReactComponent as NoData } from '../../../../assets/icons/no_modifier_groups.svg';

import styles from './categories.module.scss';
import useCategoryManager from './hooks/useCategoryManager';

// const SELECTOR_OPTIONS = [
//   { value: 'null', label: 'All' },
//   { value: 'true', label: 'Active' },
//   { value: 'false', label: 'Inactive' },
// ];

function Categories() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useAppContext();

  const queryDetails = useSelector((state) => state.categories.queryDetails);
  const status = useSelector((state) => state.categories.status);
  const categories = useSelector((state) => state.categories.categories);

  const isLoading = status === 'loading' || status === 'idle';

  const {
    category,
    isEditMode,
    isEditModalOpen,
    handleSaveCategory,
    handleShowAddModal,
    handleShowEditModal,
    handleModalClose,
    handleDeleteCategory,
    errors,
  } = useCategoryManager({ categories });

  useEffect(() => {
    if (token) {
      dispatch(fetchCategories({ categoryDetails: queryDetails, token }));
    }
  }, [dispatch, queryDetails, token]);

  const handleSetResult = (result) => {
    dispatch(setQueryDetails({ search: result }));
  };

  const handleSearchClean = () => {
    dispatch(setQueryDetails({ search: '' }));
  };

  return (
    <div className={styles.main_container}>
      <div className={styles.head_container}>
        <div className={styles.title_and_button}>
          <PageTitle name={'Categories'} />
          <div>
            <Button
              className={styles.add_button}
              title={'Add New Category'}
              onClick={handleShowAddModal}
              prefixIcon={<img src={Plus} alt="Plus_icon" />}
            />
          </div>
        </div>
        <div className={styles.list_controller}>
          <div className={styles.search}>
            <Search
              placeholder="Search..."
              onSearchResults={handleSetResult}
              onClear={handleSearchClean}
              autoFilter={false}
            />
          </div>
        </div>
      </div>
      <div className={styles.list_container}>
        <CategoryCardsSection
          isLoading={isLoading}
          length={categories.length}
          noItemsProps={{
            title: 'No Categories added.',
            description: 'Click on the «+ Add Category» button to create the first one.',
          }}
          icon={NoData}
        >
          <CategoryCards
            categories={categories}
            onClick={(id) => navigate(`/menu/categories/${id}/edit`)}
            onDelete={handleDeleteCategory}
            onEdit={handleShowEditModal}
          />
        </CategoryCardsSection>
      </div>
      {isEditModalOpen && (
        <EditCategoryModal
          category={category}
          saveChanges={handleSaveCategory}
          isModalOpen={isEditModalOpen}
          isEditMode={isEditMode}
          closeModal={handleModalClose}
          errors={errors}
        />
      )}
    </div>
  );
}

export default Categories;
