import React, { useState, useRef, useEffect, useMemo, useId } from 'react';

import { nanoid } from 'nanoid';

import clsx from 'clsx';

import { FiSearch } from 'react-icons/fi';
import { FaPlus } from 'react-icons/fa';
import { ImCross } from 'react-icons/im';
import { FaChevronDown } from 'react-icons/fa';
import { FaCheck } from 'react-icons/fa';

import styles from './tag-selector.module.scss';
import Spinner from '../spinner/spinner';

const TagSelector = ({
  items = [], // shoud be in [{value: 0, label: 'One'}] format
  selectedItemsIds: externalSelectedItems = [],
  isLoading = false,
  onChange = () => {},
  label,
  placeholder = 'Select items...',
  required = false,
  disabled = false,
  errors = [],
  customButton = null,
  isActionButtons = true,
}) => {
  const selectedItems = useMemo(
    () =>
      externalSelectedItems.reduce((result, selectedItem) => {
        const foundItem = items.find((item) => item.value === selectedItem);
        return foundItem ? [...result, foundItem] : result;
      }, []),
    [externalSelectedItems, items],
  );

  const [searchQuery, setSearchQuery] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const baseId = useId();

  const filteredItems = useMemo(
    () =>
      items?.filter((item) => {
        return item?.label.toLowerCase().includes(searchQuery.toLowerCase());
      }),
    [items, searchQuery],
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleSelectAll = () => {
    onChange?.(items.map((item) => item.value));
  };

  const handleRemoveAll = () => {
    onChange?.([]);
  };

  const handleRemove = (item) => {
    onChange?.(selectedItems.filter((i) => i.value !== item.value).map((i) => i.value));
  };

  const handleToggleItem = (item, e) => {
    e.preventDefault();
    if (selectedItems.some((i) => item.value === i.value)) {
      onChange?.(selectedItems.filter((i) => i.value !== item.value).map((i) => i.value));
    } else {
      onChange?.([...selectedItems.map((i) => i.value), item.value]);
    }
  };

  return (
    <div ref={dropdownRef} className={styles.container}>
      {/* Form Label */}
      {label && (
        <label className={clsx(styles.label, required && styles.asterisk)} htmlFor={baseId}>
          {label}
        </label>
      )}
      {/* Selector Button */}
      <button
        onClick={() => {
          setIsOpen(!isOpen);
          setSearchQuery('');
        }}
        className={styles.selector}
        name={baseId}
        disabled={disabled || isLoading}
      >
        <span className={styles.field}>
          {selectedItems.length > 0 ? (
            <span
              className={styles.value}
            >{`${selectedItems.length} item${selectedItems.length !== 1 ? 's' : ''} selected`}</span>
          ) : (
            <span className={styles.placeholder}>{placeholder}</span>
          )}
        </span>
        {isLoading ? (
          <Spinner className={styles.spinner} />
        ) : (
          <FaChevronDown className={clsx(styles.chevron, { [styles.rotate]: isOpen })} />
        )}
      </button>

      {/* Dropdown Panel */}
      {isOpen && (
        <div className={styles.dropdown_panel}>
          {/* Header Actions */}
          <div className={styles.action_buttons}>
            {
            customButton && 
              <button onClick={customButton.onClick} className={styles.select_all}>
                {customButton.icon}
                {customButton.title}
              </button>
            }
            {isActionButtons &&
              <>
                <button onClick={handleSelectAll} className={styles.select_all}>
                <FaPlus size={12} />
                  Add All
                </button>
                <button onClick={handleRemoveAll} className={styles.remove_all}>
                  <ImCross size={10} />
                  Remove All
                </button>
              </>
            }

          </div>

          {/* Search Input */}
          <div className={styles.search}>
            <div className={styles.search_container}>
              <FiSearch size={16} className={styles.lens_icon} />
              <input
                type="text"
                placeholder="Search..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                onClick={(e) => e.stopPropagation()}
              />
            </div>
          </div>

          {/* Items List */}
          <div className={styles.dropdown_list}>
            {filteredItems.map((item) => {
              const checkboxId = `${baseId}-${item.value}`;
              return (
                <div
                  key={nanoid()}
                  className={styles.item}
                  onClick={(e) => {
                    handleToggleItem(item, e);
                  }}
                >
                  <input
                    id={checkboxId}
                    type="checkbox"
                    checked={selectedItems.some((i) => i.value === item.value)}
                    onChange={(e) => handleToggleItem(item, e)}
                    onClick={(e) => e.stopPropagation()}
                  />
                  <label htmlFor={checkboxId} className={styles.checkbox_label}>
                    <span className={styles.custom_checkbox}>
                      {
                        <FaCheck
                          className={clsx(styles.check_icon, {
                            [styles.checked]: selectedItems.some((i) => i.value === item.value),
                          })}
                          size={12}
                        />
                      }
                    </span>
                  </label>
                  <span className={styles.name}>{item.label}</span>
                </div>
              );
            })}
          </div>
        </div>
      )}
      {/* Errors */}
      {!!errors.length && (
        <div className={styles.error_container}>
          {errors.map((error) => (
            <div key={nanoid()} className={styles.error}>
              {error}
            </div>
          ))}
        </div>
      )}
      {/* Tags */}
      {selectedItems.length > 0 && (
        <div className={styles.tags_container}>
          {selectedItems.map((item) => {
            return (
              <div key={nanoid()} className={styles.tag}>
                <button className={styles.button} onClick={() => handleRemove(item)} disabled={disabled}>
                  <ImCross size={8} />
                </button>
                <span>{item.label}</span>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default TagSelector;
