import React from 'react';

import clsx from 'clsx';
import styles from './textarea.module.scss';

const Textarea = ({ label, value, name = 'desc', placeholder, onChange, required, className, style, textAreaStyle }) => {
  return (
    <div className={clsx(styles.wrapper, className ? className : '')} style={style}>
      <label className={styles.label} htmlFor={name}>
        {label}
        {required ? <span className={styles.starlet}>*</span> : null}
      </label>
      <textarea className={styles.description} value={value} name={name} placeholder={placeholder} onChange={onChange} style={textAreaStyle}></textarea>
    </div>
  );
};

export default Textarea;
