import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import 'chart.js';

import { useAppContext } from '../../../context/context';
import { fetchDashboardData } from '../../../redux/dashboardSlice';

import PageTitle from '../../utilities/page-title/page-title';

import Metric from './statistics/metric';
import NetSalesBarChart from './graphs/net-sales/net-sales';
import GrossSalesBarChart from './graphs/gross-sales/gross-sales';
import PaymentMethodsChart from './graphs/payment-methods/payment-methods';
import ClockedInEmployeesWidget from './widgets/clocked-in-employees/clocked-in-employees';
import TopSellingItemsWidget from './widgets/top-selling-items/top-selling-items';

import clsx from 'clsx';
import styles from './dashboard.module.scss';

//import { Link } from 'react-router-dom'; TODO: There will be adding this code after the MVP (Do not delete)
//import Button from '../../utilities/button/button'; TODO: There will be adding this code after the MVP (Do not delete)
//import DatePicker from '../../utilities/date-picker/date-picker'; TODO: There will be adding this code after the MVP (Do not delete)
//import HourlyRevenueLineChart from './graphs/hourly-new-revenue/hourly-new-revenue'; TODO: There will be adding this code after the MVP (Do not delete)
//import DiscountLineChart from './graphs/discounts/discounts'; TODO: There will be adding this code after the MVP (Do not delete)
//import GuestsLineChart from './graphs/guests/guests'; TODO: There will be adding this code after the MVP (Do not delete)
//import qrCode from '../../../assets/img/qr_code.png'; TODO: There will be adding this code after the MVP (Do not delete)

//const DATE_FORMAT = 'MM/dd/yyyy p'; TODO: There will be adding this code after the MVP (Do not delete)
//const TIME_FORMAT = 'p'; TODO: There will be adding this code after the MVP (Do not delete)

const adjustForTimeZone = (date) => {
  const offsetInMinutes = date.getTimezoneOffset();
  return new Date(date.getTime() - offsetInMinutes * 60000);
};

function Dashboard() {
  // Hooks
  const dispatch = useDispatch();
  const { token } = useAppContext();

  // Store
  const dashboardData = useSelector((state) => state.dashboard.dashboard_data);
  const status = useSelector((state) => state.dashboard.status);

  // Local state
  const today = new Date(); // Date
  const [fromDateISO, setFromDateISO] = useState(null); // Date
  const [toDateISO, setToDateISO] = useState(null); // Date
  const [metrics, setMetrics] = useState([]); // Metrics
  const [netSalesGraphData, setNetSalesGraphData] = useState({}); // Graphs
  const [grossSalesGraphData, setGrossSalesGraphData] = useState({}); // Graphs
  const [paymentMethodsGraphData, setPaymentMethodsGraphData] = useState({}); // Graphs
  const [clockedInEmployeesData, setClockedInEmployeesData] = useState({}); // Info block
  const [topSellingItemsData, setTopSellingItemsData] = useState({}); // Info block

  //const [fromDate, setFromDate] = useState(today); // Date TODO: There will be adding this code after the MVP (Do not delete)
  //const [toDate, setToDate] = useState(today); // Date TODO: There will be adding this code after the MVP (Do not delete)
  //const [hourlyNewRevenueGraphData, setHourlyNewRevenueGraphData] = useState({}); // TODO: There will be adding this code after the MVP (Do not delete)
  //const [discountsGraphData, setDiscountsGraphData] = useState({}); // TODO: There will be adding this code after the MVP (Do not delete)
  //const [guestsGraphData, setGuestsGraphData] = useState({}); // TODO: There will be adding this code after the MVP (Do not delete)

  // Effects
  useEffect(() => {
    if (token) {
      dispatch(fetchDashboardData({ token }));
    }
  }, [dispatch, token]);

  useEffect(() => {
    if (
      dashboardData &&
      dashboardData.metrics &&
      dashboardData.graphs &&
      dashboardData.metrics.length > 0 &&
      dashboardData.graphs.length > 0
    ) {
      setMetrics(dashboardData.metrics);
      setNetSalesGraphData(dashboardData.graphs[0]);
      setGrossSalesGraphData(dashboardData.graphs[1]);
      setPaymentMethodsGraphData(dashboardData.graphs[3].values);
      setClockedInEmployeesData(dashboardData.graphs[5]);
      setTopSellingItemsData(dashboardData.graphs[6]);

      //setHourlyNewRevenueGraphData(dashboard_data.graphs[2].values); TODO: There will be adding this code after the MVP (Do not delete)
      //setDiscountsGraphData(dashboard_data.graphs[4]); TODO: There will be adding this code after the MVP (Do not delete)
      //setGuestsGraphData(dashboard_data.graphs[5]); TODO: There will be adding this code after the MVP (Do not delete)
    }
  }, [dashboardData]);

/*   useEffect(() => { TODO: There will be adding this code after the MVP (Do not delete)
    if (!fromDate || !toDate) return;

    const adjustedClockIn = adjustForTimeZone(fromDate);
    const adjustedClockOut = adjustForTimeZone(toDate);

    setFromDateISO(adjustedClockIn.toISOString());
    setToDateISO(adjustedClockOut.toISOString());
  }, [fromDate, toDate]); */

    useEffect(() => {
    if (!today) return;

    const adjustedTyme = adjustForTimeZone(today);

    setFromDateISO(adjustedTyme.toISOString());
    setToDateISO(adjustedTyme.toISOString());
  }, [today]);

  // Handlers
/*   const handleSelectFromDate = (date) => { TODO: There will be adding this code after the MVP (Do not delete)
    setFromDate(new Date(date));
  }; */

/*   const handleSelectToDate = (date) => { TODO: There will be adding this code after the MVP (Do not delete)
    setToDate(new Date(date));
  }; */

/*   const handleAddNewItem = () => { TODO: There will be adding this code after the MVP (Do not delete)
    dispatch(fetchDashboardData({ start_date: fromDateISO, end_date: toDateISO, token }));
  }; */

  console.log("dashboardData", dashboardData);

  return (
    <div className={styles.dashboard}>
      <PageTitle name={'Dashboard'} className={styles.title} />
{/*       <div className={styles.filters}> TODO: There will be adding this code after the MVP (Do not delete)
        <DatePicker
          selected={fromDate}
          onChange={(date) => handleSelectFromDate(date)}
          showTimeSelect
          dateFormat={DATE_FORMAT}
          timeFormat={TIME_FORMAT}
          timeIntervals={15}
          className={styles.datepicker}
          placeholderText="Select a date and time"
        />
        <DatePicker
          selected={toDate}
          onChange={(date) => handleSelectToDate(date)}
          showTimeSelect
          dateFormat={DATE_FORMAT}
          timeFormat={TIME_FORMAT}
          timeIntervals={15}
          className={styles.datepicker}
          placeholderText="Select a date and time"
        />
        <Button className={styles.search_button} title={'Search'} onClick={handleAddNewItem} />
      </div> */}

      <div className={styles.metrics}>
        <div className={styles.row}>
          <div className={styles.graph_item}>
            {netSalesGraphData.values && (
              <NetSalesBarChart
                data={netSalesGraphData}
                startDate={fromDateISO}
                endDate={toDateISO}
                isLoading={status === 'loading'}
              />
            )}
          </div>
          <div className={styles.graph_item}>
            {grossSalesGraphData.values && (
              <GrossSalesBarChart
                data={grossSalesGraphData}
                startDate={fromDateISO}
                endDate={toDateISO}
                isLoading={status === 'loading'}
              />
            )}
          </div>
          <div className={styles.statistics}>
            <PageTitle name={'Today`s Statistics'} className={styles.subtitle} />
            <div className={styles.metrics}>
              {metrics && metrics.map((metric) => <Metric item={metric} key={metric.id} />)}
            </div>
          </div>
        </div>

{/*         <div className={styles.row}>  TODO: There will be adding this code after the MVP (Do not delete)
          <div className={styles.graph_item_full}>
            <PageTitle name={'Hourly New Revenue'} className={styles.subtitle} />
            <div className={styles.graph_content} style={{ marginBottom: '20px' }}>
              <HourlyRevenueLineChart data={hourlyNewRevenueGraphData} isLoading={status === 'loading'} />
            </div>
          </div>
        </div> */}

        <div className={clsx(styles.row, styles.row_align_left)}>
          <div className={styles.graph_item}>
            <PageTitle name={'Payment Methods'} className={styles.subtitle} />
            <div className={styles.graph_content}>
              <PaymentMethodsChart data={paymentMethodsGraphData} isLoading={status === 'loading'} />
            </div>
          </div>
          <div className={clsx(styles.graph_item, styles.graph_item_align_left)}>
            {clockedInEmployeesData.employees && (
              <ClockedInEmployeesWidget
                data={clockedInEmployeesData}
                startDate={fromDateISO}
                endDate={toDateISO}
                isLoading={status === 'loading'}
              />
            )}
            {/* {discountsGraphData.values && (  TODO: There will be adding this code after the MVP (Do not delete)
              <DiscountLineChart
                data={discountsGraphData}
                startDate={fromDateISO}
                endDate={toDateISO}
                isLoading={status === 'loading'}
              />
            )} */}
          </div>
          <div className={clsx(styles.graph_item, styles.graph_item_align_left)}>
            {topSellingItemsData.items && (
              <TopSellingItemsWidget data={topSellingItemsData} isLoading={status === 'loading'} />
            )}
            {/* {guestsGraphData.values && (  TODO: There will be adding this code after the MVP (Do not delete)
              <GuestsLineChart
                data={guestsGraphData}
                startDate={fromDateISO}
                endDate={toDateISO}
                isLoading={status === 'loading'}
              />
            )} */}
          </div>
        </div>

        {/* <div className={clsx(styles.row, styles.row_align_left)}>  TODO: There will be adding this code after the MVP (Do not delete)
          <div className={clsx(styles.graph_item, styles.graph_item_align_left)}>
            <div className={styles.graph_content}>
              {clockedInEmployeesData.employees && (
                <ClockedInEmployeesWidget
                  data={clockedInEmployeesData}
                  startDate={fromDateISO}
                  endDate={toDateISO}
                  isLoading={status === 'loading'}
                />
              )}
            </div>
          </div>
          <div className={clsx(styles.graph_item, styles.graph_item_align_left)}>
            <div className={styles.graph_content}>
              {topSellingItemsData.items && (
                <TopSellingItemsWidget
                  data={topSellingItemsData}
                  startDate={fromDateISO}
                  endDate={toDateISO}
                  isLoading={status === 'loading'}
                />
              )}
            </div>
          </div>
        </div> */}
      </div>

      {/* <div className={styles.footer}>  TODO: There will be adding this code after the MVP (Do not delete)
        <div className={styles.links}>
          <div className={styles.link_wrapper}>
            <span className={styles.page_name}>Home Page</span>
            <Link to="/business/information" className={styles.link}>
              https://somewebsite.com
            </Link>
          </div>
          <div className={styles.link_wrapper}>
            <span className={styles.page_name}>Menu Page</span>
            <Link to="/menu/items" className={styles.link}>
              https://anotherwebsite.com
            </Link>
          </div>
        </div>
        <div className={styles.qr_code_wrapper}>
          <div className={styles.qr_code}>
            <img src={qrCode} alt="QR Code" />
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default Dashboard;
