import { ReactComponent as GrossSales } from '../../../../assets/icons/gross_sale.svg';
import { ReactComponent as Tax } from '../../../../assets/icons/tax.svg';
import { ReactComponent as Tips } from '../../../../assets/icons/tips.svg';
import { ReactComponent as Discount } from '../../../../assets/icons/cash_discount.svg';
import { ReactComponent as Tendered } from '../../../../assets/icons/tender.svg';
import { ReactComponent as NetSales } from '../../../../assets/icons/ic_profit.svg';
import { ReactComponent as ServiceCharges } from '../../../../assets/icons/service_charge.svg';
import { ReactComponent as Refunds } from '../../../../assets/icons/refund.svg';
import { ReactComponent as Wastage } from '../../../../assets/icons/refund.svg';
import { ReactComponent as LoyaltyRewards } from '../../../../assets/icons/reward.svg';
import { ReactComponent as TotalTransactions } from '../../../../assets/icons/ic_transactions.svg';

export const DashboardMetricType = {
  GrossSales: {
    name: 'Gross Sales',
    Icon: GrossSales,
  },
  Tax: { name: 'Tax', Icon: Tax },
  Tips: { name: 'Tips', Icon: Tips },
  Discount: { name: 'Discount', Icon: Discount },
  Tendered: { name: 'Tendered', Icon: Tendered },
  NetSales: { name: 'Net Sales', Icon: NetSales },
  ServiceCharges: { name: 'Service Charges', Icon: ServiceCharges },
  Refunds: { name: 'Refunds', Icon: Refunds },
  Wastage: { name: 'Wastage', Icon: Wastage },
  LoyaltyRewards: { name: 'Loyalty Rewards', Icon: LoyaltyRewards },
  TotalTransactions: { name: 'Total Transactions', Icon: TotalTransactions },
};
