import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import Switch from '../../../../utilities/switch/switch';
import NoImage from '../../../../utilities/no-image/no-image';
import { hexToRgba, formatNumberToFixed } from '../../../../utilities/utils';

import IconEllipsis from '../../../../../assets/icons/ellipsis.png';
import clsx from 'clsx';
import styles from './item.module.scss';

const MenuItem = ({
  item_info,
  categories,
  onClone,
  onShow,
  onEdit,
  onDelete,
  onToggleActive,
  isLoading,
  isDropdownOutOfEdge,
}) => {
  // Hooks
  const navigate = useNavigate();

  // Local state
  const [currentCategory, setCurrentCategory] = useState([]); // State for categories, because there is a possibility that there are multiple categories assigned to one item
  const [currentToggleStatus, setCurrentToggleStatus] = useState(item_info.hide_status); // State for toggle switch to see animation of hiding/unhiding after clicking on Switch

  // Refs
  const [activeDropdownId, setActiveDropdownId] = useState(null);
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);

  // Effects
  useEffect(() => {
    // Assign possible multiple categories to the item
    if (item_info?.category_ids?.length > 0 && categories?.length > 0) {
      const matchedCategories = item_info.category_ids
        .map((assignedId) => categories.find((category) => category.id === assignedId))
        .filter(Boolean);
      setCurrentCategory(matchedCategories);
    }
  }, [categories, item_info]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        setActiveDropdownId(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    // Set the current toggle status, exist because of the animation
    setCurrentToggleStatus(item_info.hide_status);
  }, [item_info]);

  // Handlers
  const toggleDropdown = (id) => {
    // Function to toggle the dropdown
    setActiveDropdownId((prev) => (prev === id ? null : id));
  };

  const handleActionClick = (action, id) => {
    // Function to handle actions from the dropdown
    setActiveDropdownId(null);
    if (action === 'edit') onEdit(id);
    if (action === 'delete') onDelete(id);
    if (action === 'clone') onClone(id);
  };

  const handleToggleActive = (id, value) => {
    // Function to handle toggling switcher on the item, exist because of the animation
    const status = value ? 'UnHide' : 'HideForToday';
    setCurrentToggleStatus(status);
    onToggleActive(id, status);
  };

  const handlerCategoryTagClick = (id) => {
    if (id) {
      navigate(`/menu/categories/${id}/edit`);
    }
  };

  return (
    <div className={styles.menu_item}>
      <div className={styles.info_wrapper}>
        <div className={styles.row}>
          <h5 className={styles.title} onClick={() => onShow(item_info.id)}>
            {item_info.name}
          </h5>
          <div className={styles.actions} ref={buttonRef}>
            <img
              src={IconEllipsis}
              alt="ellipsis icon"
              className={styles.ellipsis_icon}
              onClick={() => toggleDropdown(item_info.id)}
            />
            {activeDropdownId === item_info.id && (
              <ul className={clsx(styles.dropdown, { [styles.out_of_edge]: isDropdownOutOfEdge })} ref={dropdownRef}>
                <li onClick={() => handleActionClick('edit', item_info.id)}>Edit</li>
                <li onClick={() => handleActionClick('delete', item_info.id)}>Delete</li>
                <li onClick={() => handleActionClick('clone', item_info.id)}>Clone</li>
              </ul>
            )}
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.price}>${formatNumberToFixed(item_info.price)}</div>
          <div className={styles.categories_wrapper}>
            {currentCategory.map((category) => (
              <span
                key={category.id}
                className={styles.category}
                onClick={() => handlerCategoryTagClick(category.id)}
                style={{
                  backgroundColor: category?.color_code !== '' ? hexToRgba(category.color_code, 0.3) : '#fdaf48',
                  color: category?.color_code !== '' ? category.color_code : '#ffffff',
                }}
              >
                {category.name ? category.name : 'uncategorized'}
              </span>
            ))}
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.pos_text}>On POS</div>
          <div className={styles.pos_switch}>
            <Switch
              id={item_info.id}
              isActive={currentToggleStatus === 'UnHide'}
              onSwitch={(id, status) => handleToggleActive(id, status)}
            />
          </div>
        </div>
      </div>

      <div className={styles.image_wrapper} onClick={() => onShow(item_info.id)}>
        {/*         {item_info.image && item_info.image !== '' ? ( // TODO: Uncomment when backend logic with .blob will be ready
          <img src={item_info.image} alt={item_info.name} className={styles.image} />
        ) : (
          <NoImage title="Image not added" />
        )} */}
        <NoImage title="Image not added" />
      </div>
    </div>
  );
};

export default MenuItem;
