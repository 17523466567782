import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { fetchEmployees, fetchEmployeesForLength, setQueryDetails } from '../../../../redux/employeesSlice';

import { fetchTeamRoles } from '../../../../redux/rolesSlice';

import { IoIosArrowDown } from 'react-icons/io';

import { useAppContext } from '../../../../context/context';

import { ReactComponent as NoEmployees } from '../../../../assets/icons/no_employees.svg';

import PageTitle from '../../../../components/utilities/page-title/page-title';
import Toggler from '../../../../components/utilities/toggler/toggler';
import Selector from '../../../../components/utilities/selector/selector';
import Search from '../../../../components//utilities/search/search';
import Pagination from '../../../../components/utilities/pagination/pagination';
import Button from '../../../../components/utilities/button/button';

import { isObjectEmpty } from '../../../utilities/utils';

import useEmployee from './hooks/useEmployee';

import EmployeeList from './employees-list';
import EmployeeEditModal from './modals/employee-edit-modal';
import EmployeeInfoModal from './modals/employee-info-modal';

import Plus from '../../../../assets/icons/plus.svg';
import { NoItems } from '../../../utilities';

import styles from './employees.module.scss';

const PAGINATION_OPTIONS = [
  { label: 10, value: 10 },
  { label: 15, value: 15 },
  { label: 20, value: 20 },
  { label: 25, value: 25 },
];

const PAGINATION = {
  search: '',
  page: 1,
  perPage: 10,
};

function Employees() {
  const [isInitialized, setIsInitialized] = useState(false);

  const queryDetails = useSelector((state) => state.employees.queryDetails);
  const employeesStatus = useSelector((state) => state.employees.status);
  const employees = useSelector((state) => state.employees.employees);
  const rolesOptions = useSelector((state) => state.roles.nameOptions);
  const numberOfAllEmployees = useSelector((state) => state.employees.numberOfAllEmployees);

  const [selectedRole, setSelectedRole] = useState({});
  const [toggleInactive, setToggleInactive] = useState(true);

  const inactiveEmployees = useMemo(() => employees.filter((employee) => employee.is_active === false), [employees]);
  const activeEmployees = useMemo(() => employees.filter((employee) => employee.is_active === true), [employees]);

  const isLoading = employeesStatus === 'loading' || employeesStatus === 'idle';

  const currentEmployees = toggleInactive ? activeEmployees : inactiveEmployees;

  const {
    employee,
    setEmployee,
    isEditModalOpen,
    isInfoModalOpen,
    isEditMode,
    errors,
    handleModalClose,
    handleOpenEmployeeInfo,
    handleAddNewEmployee,
    handleDeleteEmployee,
    handleChangeStatus,
    handleEditEmployee,
    handleSaveChanges,
  } = useEmployee(currentEmployees);

  const dispatch = useDispatch();
  const { token } = useAppContext();

  useEffect(() => {
    if (!isInitialized) {
      if (isObjectEmpty(queryDetails)) {
        dispatch(setQueryDetails(PAGINATION));
        dispatch(fetchTeamRoles(token));
      }
      setIsInitialized(true);
    }

    if (isInitialized && token) {
      dispatch(fetchEmployees({ token, queryDetails }));
      dispatch(fetchEmployeesForLength({ token }));
    }
  }, [dispatch, queryDetails, isInitialized, token]);

  const handleSetResult = (result) => {
    dispatch(setQueryDetails({ search: result }));
  };

  const handleSelectRole = (value) => {
    setSelectedRole({ label: value, value: +value });
    dispatch(setQueryDetails({ team_role_id: value === 'All roles' ? null : value }));
  };

  const handleSearchClean = () => {
    dispatch(setQueryDetails({ search: '' }));
  };

  //----Pagination Controls---//
  const handleSelectPageCount = (value) => {
    dispatch(setQueryDetails({ perPage: value }));
  };

  const handleClickPrev = () => {
    if (queryDetails.page > 1) {
      dispatch(setQueryDetails({ page: queryDetails.page - 1 }));
    }
  };

  const handleClickNext = () => {
    dispatch(setQueryDetails({ page: queryDetails.page + 1 }));
  };

  return (
    <div className={styles.main_container}>
      <div className={styles.head_container}>
        <div className={styles.title_and_button}>
          <PageTitle name={'Employees'} />
          <div>
            <Button
              title={'Add Employee'}
              onClick={handleAddNewEmployee}
              prefixIcon={<img src={Plus} alt="Plus_icon" />}
            />
          </div>
        </div>
        <div className={styles.list_controller}>
          <div className={styles.role_selector}>
            <Selector
              icon={IoIosArrowDown}
              items={rolesOptions}
              additionalItems={[{ label: 'All roles', value: null }]}
              selectedItem={selectedRole.label}
              placeholder={'Select Role'}
              onSelectItem={handleSelectRole}
              initialPlaceholderVisibility={false}
            />
          </div>
          <div className={styles.toggler}>
            <Toggler toggle={toggleInactive} onToggle={setToggleInactive} />
          </div>
          <div className={styles.search}>
            <Search
              placeholder="Search..."
              onSearchResults={handleSetResult}
              onClear={handleSearchClean}
              autoFilter={false}
            />
          </div>
        </div>
      </div>
      <div className={styles.list_container}>
        {currentEmployees?.length < 1 ? (
          <NoItems
            title={toggleInactive ? 'No employees added.' : 'No inactive employees.'}
            description={toggleInactive ? 'Click on the «+ Add employee» button to create the first one.' : ''}
            icon={NoEmployees}
            isLoading={isLoading}
          />
        ) : (
          <>
            <EmployeeList
              employees={currentEmployees}
              roles={rolesOptions}
              onOpen={handleOpenEmployeeInfo}
              onToggleActive={handleChangeStatus}
              isLoading={isLoading}
            />
            <Pagination
              pageSizeOptions={PAGINATION_OPTIONS}
              numberOfItems={numberOfAllEmployees}
              currentPage={queryDetails.page}
              currentPageSize={queryDetails.perPage}
              onPageSizeChange={handleSelectPageCount}
              onPrev={handleClickPrev}
              onNext={handleClickNext}
            />
          </>
        )}
      </div>
      <EmployeeEditModal
        employee={employee}
        setEmployee={setEmployee}
        isLoading={isLoading}
        isOpen={isEditModalOpen}
        isEditMode={isEditMode}
        closeModal={handleModalClose}
        saveChanges={handleSaveChanges}
        errors={errors}
      />
      <EmployeeInfoModal
        employee={employee}
        isLoading={isLoading}
        isOpen={isInfoModalOpen}
        onCloseModal={handleModalClose}
        onEdit={handleEditEmployee}
        onDelete={handleDeleteEmployee}
      />
    </div>
  );
}

export default Employees;
