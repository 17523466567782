import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Search } from '../search';
import { DropdownTypes } from './dropdown-types';

import Selector from '../../../../utilities/selector/selector';
import { isAnArray, isArrayEmpty } from '../../../../utilities/utils';

import { ImCross } from 'react-icons/im';

import { useAppContext } from '../../../../../context/context';
import { fetchTaxes } from '../../../../../redux/taxesSlice';
import { fetchAllEmployee } from '../../../../../redux/employeesSlice';
import { fetchModifiers } from '../../../../../redux/modifierSetsSlice';
import { fetchCategories } from '../../../../../redux/categoriesSlice';
import { fetchAllItems } from '../../../../../redux/itemsSetSlice';
import { fetchOrderTypes } from '../../../../../redux/orderTypesSlice';
import { fetchTeamRoles } from '../../../../../redux/rolesSlice';
import { fetchTerminalSettings } from '../../../../../redux/terminalSettingsSlice';

import styles from './report-filter-panel.module.scss';

const transformToSelectorItems = (items) => {
  if (isAnArray(items) && !isArrayEmpty(items)) {
    return items.map((item) => ({
      value: item.id,
      label: item.name,
    }));
  }

  return [];
};
const ReportFilterPanel = ({
  filterParams,
  dropdowns = [],
  searchShow = true,
  searchTitle = '',
  additionalFilter = null,
  onClearFilterParams,
  onUpdateParams,
}) => {
  const { token } = useAppContext();
  const dispatch = useDispatch();

  const employees = useSelector((state) => state.employees.employees);
  const taxes = useSelector((state) => state.taxes.taxes);
  const modifiers = useSelector((state) => state.modifierSets.modifierSets);
  const categories = useSelector((state) => state.categories.categories);
  const items = useSelector((state) => state.itemsSet.items);
  const orderTypes = useSelector((state) => state.orderTypes.orderTypes);
  const roles = useSelector((state) => state.roles.roles);
  const terminals = useSelector((state) => state.terminalSettings.terminalSettings);

  useEffect(() => {
    if (dropdowns.includes(DropdownTypes.employee)) {
      dispatch(fetchAllEmployee({ token }));
    }
    if (dropdowns.includes(DropdownTypes.tax_id)) {
      dispatch(fetchTaxes(token));
    }
    if (dropdowns.includes(DropdownTypes.modifier_id)) {
      dispatch(fetchModifiers({ token }));
    }
    if (dropdowns.includes(DropdownTypes.category_id)) {
      dispatch(fetchCategories({ token }));
    }
    if (dropdowns.includes(DropdownTypes.item_id)) {
      dispatch(fetchAllItems({ token }));
    }
    if (dropdowns.includes(DropdownTypes.order_type_id)) {
      dispatch(fetchOrderTypes({ token }));
    }
    if (dropdowns.includes(DropdownTypes.roleId)) {
      dispatch(fetchTeamRoles({ token }));
    }
    if (dropdowns.includes(DropdownTypes.terminal_id)) {
      dispatch(fetchTerminalSettings({ token }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    onUpdateParams({ [name]: value });
  };

  const handleSearch = (result) => {
    onUpdateParams({ search: result });
  };

  const handleCleanSearch = () => {
    onUpdateParams({ search: '' });
  };

  const dropDownTemplate = ({ name, label, items, additionalItems, selectedId = '' }) => {
    return (
      <div className={styles.field} key={name}>
        <Selector
          items={items}
          additionalItems={additionalItems}
          name={name}
          selectedItem={selectedId}
          label={label}
          onSelectItem={(value) => handleInputChange({ target: { name, value } })}
        />
      </div>
    );
  };

  const data = {
    paymentTypes: [
      { id: 1, name: 'All' },
      { id: 2, name: 'Cash' },
      { id: 3, name: 'Card' },
    ],
    orderTypes: [
      { id: 1, name: 'Order Type 1' },
      { id: 2, name: 'Order Type 2' },
    ],
    terminals: [
      { id: 1, name: 'Terminal 1' },
      { id: 2, name: 'Terminal 2' },
    ],
    captured: [
      { id: '', name: 'All Status' },
      { id: true, name: 'Captured' },
      { id: false, name: 'Not Captured' },
    ],
    cardType: [
      { id: '', name: 'All Card Types' },
      { id: 'Debit', name: 'Debit' },
      { id: 'Credit', name: 'Credit' },
    ],
    dataRange: [
      { id: 1, name: 'Today' },
      { id: 2, name: 'Yesterday' },
      { id: 3, name: 'This Week' },
      { id: 4, name: 'Last Week' },
      { id: 5, name: 'This Month' },
      { id: 6, name: 'Last Month' },
    ],
    team: [
      { id: 1, name: 'Team 1' },
      { id: 2, name: 'Team 2' },
    ],
    roles: [
      { id: 1, name: 'Role 1' },
      { id: 2, name: 'Role 2' },
    ],
    cashDiscounts: [
      { id: 1, name: 'Cash Discount' },
      { id: 2, name: 'SurCharge' },
      { id: 3, name: 'All' },
    ],
  };

  const configs = [
    {
      name: 'employee_id',
      label: 'Employee',
      items: transformToSelectorItems(employees),
      additionalItems: [{ value: '', label: 'All' }],
      selectedId: filterParams?.employee_id,
    },
    {
      name: 'payment_type',
      label: 'Payment Type',
      items: transformToSelectorItems(data.paymentTypes),
      selectedId: filterParams?.payment_type,
    },
    {
      name: 'order_type_id',
      label: 'Order Type',
      items: transformToSelectorItems(orderTypes),
      additionalItems: [{ value: '', label: 'All' }],
      selectedId: filterParams?.order_type_id,
    },
    {
      name: 'terminal_id',
      label: 'Terminal',
      items: transformToSelectorItems(terminals),
      additionalItems: [{ value: '', label: 'All' }],
      selectedId: filterParams?.terminal_id,
    },
    { name: 'tax_id', label: 'Tax', items: transformToSelectorItems(taxes), selectedId: filterParams?.tax_id },
    {
      name: 'modifier_id',
      label: 'Modifier',
      items: transformToSelectorItems(modifiers),
      selectedId: filterParams?.modifier_id,
    },
    {
      name: 'category_id',
      label: 'Category',
      items: transformToSelectorItems(categories),
      selectedId: filterParams?.category_id,
    },
    { name: 'item_id', label: 'Item', items: transformToSelectorItems(items), selectedId: filterParams?.item_id },
    {
      name: 'captured_id',
      label: 'Is Captured',
      items: transformToSelectorItems(data.captured),
      selectedId: filterParams?.captured_id,
    },
    {
      name: 'card_type',
      label: 'Card Type',
      items: transformToSelectorItems(data.cardType),
      selectedId: filterParams?.card_type,
    },
    {
      name: 'date_range',
      label: 'Date Range',
      items: transformToSelectorItems(data.dataRange),
      selectedId: filterParams?.date_range,
    },
    { name: 'teamId', label: 'Team', items: transformToSelectorItems(data.team), selectedId: filterParams?.teamId },
    { name: 'roleId', label: 'Role', items: transformToSelectorItems(roles), selectedId: filterParams?.roleId },
    {
      name: 'cashDiscountSurChargeId',
      label: 'Cash Discount/SurCharge',
      items: transformToSelectorItems(data.cashDiscounts),
      selectedId: filterParams?.cashDiscountSurChargeId,
    },
  ];

  return (
    <div>
      {searchShow && (
        <div className={styles.search}>
          <div className={styles.filter_container}>
            <h4 className={styles.filter_title}>Search</h4>
          </div>
          <div className={styles.search_field}>
            <Search searchTitle={searchTitle} onSearch={handleSearch} onCleanSearch={handleCleanSearch} />
          </div>
        </div>
      )}

      {dropdowns.length > 0 && (
        <div className={styles.filter_by}>
          <div className={styles.filter_container}>
            <h4 className={styles.filter_title}>Filter By</h4>
            <div className={styles.clear_filter} onClick={onClearFilterParams}>
              <div className={styles.cross_icon}>
                <ImCross />
              </div>
              <span className={styles.text}>Clear Filters</span>
            </div>
          </div>
          <div className={styles} key="col">
            <div className={styles.fields}>
              {configs.filter((c) => dropdowns.includes(c.name)).map((config) => dropDownTemplate(config))}
            </div>
          </div>
        </div>
      )}

      {additionalFilter}
    </div>
  );
};

export default ReportFilterPanel;
