import { useState, useEffect } from 'react';

const useFetch = (apiCallback, fetchCondition = true, dependencies = []) => {
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        if (!fetchCondition) {
          return;
        }

        const response = await apiCallback();

        if (response.status !== 200) {
          throw new Error('Network response was not ok');
        }

        setData(response.data || response);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    // Cleanup function
    return () => {
      // Cleanup logic if needed
    };
  }, [...dependencies, fetchCondition]);

  return { data, isLoading, error };
};

export default useFetch;
