import clsx from 'clsx';
import styles from './button.module.scss';

const Button = ({
  title,
  type,
  onClick,
  disabled,
  variant,
  style,
  className,
  buttonClassName,
  prefixIcon,
  suffixIcon,
}) => {
  const renderIcon = (icon, styleClass) => {
    return icon && <div className={styles[styleClass]}>{icon}</div>;
  };

  return (
    <div className={clsx(styles.container, className)} onClick={onClick}>
      {renderIcon(prefixIcon, 'prefix_icon')}
      {renderIcon(suffixIcon, 'suffix_icon')}
      <button
        type={type}
        disabled={disabled}
        className={clsx(styles[`variant_${variant}`], buttonClassName)}
        style={style}
      >
        {title}
      </button>
    </div>
  );
};
export default Button;
