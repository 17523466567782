import DatePicker from '../../../utilities/date-picker/date-picker';
import List from '../../../utilities/list/list';
import styles from './timesheet-details-list.module.scss';

const TimesheetDetailsEditList = ({ timesheetDetails, onEdit, isLoading }) => {
  if (!timesheetDetails) return null;

  const renderClockIn = (item) => (
    <DatePicker
      selected={item.fromDate}
      onChange={(date) => onEdit(date, 'fromDate')}
      showTimeSelect
      dateFormat="yyyy-MM-dd HH:mm"
      timeFormat="HH:mm"
      timeIntervals={15}
      className={styles.datepicker}
      placeholderText="Select a date and time"
    />
  );

  const renderClockOut = (item) => (
    <DatePicker
      selected={item.toDate}
      onChange={(date) => onEdit(date, 'toDate')}
      showTimeSelect
      dateFormat="yyyy-MM-dd HH:mm"
      timeFormat="HH:mm"
      timeIntervals={15}
      className={styles.datepicker}
      placeholderText="Select a date and time"
    />
  );

  const columnConfig = [
    { key: '', header: '#' },
    { key: 'fromDate', header: 'Clock In', render: renderClockIn },
    { key: 'toDate', header: 'Clock Out', render: renderClockOut },
    { key: 'recordedStatus', header: 'Status' },
  ];

  return <List data={timesheetDetails} columnConfig={columnConfig} isLoading={isLoading} />;
};

export default TimesheetDetailsEditList;
