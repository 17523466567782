import { useState, useEffect, useRef } from 'react';
import clsx from 'clsx';

import useEdgeClick from '../../../../utilities/useEdgeClick';
import { hexToRgba } from '../../../../utilities/utils';

import styles from './category-cards.module.scss';

const CategoryCard = ({ category, onClick, onEdit, onDelete, isDropdownOutOfEdge }) => {
  const [activeDropdownId, setActiveDropdownId] = useState(null);
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        setActiveDropdownId(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleActionClick = (action, id) => {
    setActiveDropdownId(null);
    if (action === 'edit') onEdit(id);
    if (action === 'delete') onDelete(id);
  };

  const toggleDropdown = (id) => {
    setActiveDropdownId((prev) => (prev === id ? null : id));
  };

  return (
    <div className={styles.category_card}>
      <div className={styles.head}>
        <div className={styles.name_and_button}>
          <p className={styles.category_name} onClick={() => handleActionClick('edit', category.id)}>
            {category.name}
          </p>
          <div className={styles.ellipsis_button} onClick={() => toggleDropdown(category.id)} ref={buttonRef}>
            <button className={styles.ellipsis_icon}></button>
            {activeDropdownId === category.id && (
              <ul className={clsx(styles.dropdown, { [styles.out_of_edge]: isDropdownOutOfEdge })} ref={dropdownRef}>
                <li onClick={() => handleActionClick('edit', category.id)}>Edit</li>
                <li onClick={() => handleActionClick('delete', category.id)}>Delete</li>
              </ul>
            )}
          </div>
        </div>
        <p className={styles.items_counter}>{`${category.item_ids.length} items`}</p>
      </div>
      <div
        className={styles.color_block}
        onClick={() => onClick(category.id)}
        style={{ '--card-color': hexToRgba(category?.color_code || '#232323') }}
      ></div>
    </div>
  );
};

const CategoryCards = ({ categories, onClick, onEdit, onDelete }) => {
  const { ref, position, handleClick } = useEdgeClick(110);

  const isDropdownOutOfEdge = position.includes('right');

  return (
    <div className={styles.category_cards} ref={ref} onClick={handleClick}>
      {categories.map((category) => (
        <CategoryCard
          category={category}
          onClick={onClick}
          onDelete={onDelete}
          onEdit={onEdit}
          key={category.id}
          isDropdownOutOfEdge={isDropdownOutOfEdge}
        />
      ))}
    </div>
  );
};

export default CategoryCards;
