import { z } from 'zod';

export const registrationSchema = z.object({
  name: z
    .string({ required_error: 'Please enter Restaurant Name' })
    .min(1, { message: 'Restaurant Name must contain at least 1 characters' })
    .max(50, { message: 'Restaurant Name can contain a maximum of 50 characters' }),

  phone_number: z
    .string({ required_error: 'Please enter Phone number' })
    .min(7, { message: 'Phone must contain at least 7 characters' })
    .max(16, { message: 'Phone can contain a maximum of 16 characters' })
    .regex(/^\d+$/, { message: 'Phone number must contain only digits' }),

  email: z
    .string({ required_error: 'Please enter Email' })
    .min(3, { message: 'Email must contain at least 3 characters' })
    .email({ message: 'Please enter a valid Email Address' }),

  website: z
    .string({ required_error: 'Please enter Website' })
    .min(3, { message: 'Website must contain at least 3 characters' })
    .url({ message: 'Please enter a valid URL, starting from https://www.' }),
});
