export const DATE_FORMAT = 'p';
export const OUTPUT_DATE_FORMAT = 'HH:mm';

export const statusValues = [
  { value: true, label: 'Open' },
  { value: false, label: 'Closed' },
];

export const daysOfTheWeek = [
  { value: 'Monday', label: 'Monday' },
  { value: 'Tuesday', label: 'Tuesday' },
  { value: 'Wednesday', label: 'Wednesday' },
  { value: 'Thursday', label: 'Thursday' },
  { value: 'Friday', label: 'Friday' },
  { value: 'Saturday', label: 'Saturday' },
  { value: 'Sunday', label: 'Sunday' },
];
