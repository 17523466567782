import useEdgeClick from '../../../../utilities/useEdgeClick';

import ModifierGroupsCard from './modifier-groups-card';

import styles from './modifier-groups-cards.module.scss';

const ModifierGroupsCards = ({ modifiers, onEdit, onDelete }) => {
  const { ref, position, handleClick } = useEdgeClick(110);

  const isDropdownOutOfEdge = position.includes('right');

  return (
    <div className={styles.modifier_cards} ref={ref} onClick={handleClick}>
      {modifiers.map((modifier) => (
        <ModifierGroupsCard
          modifierGroup={modifier}
          onDelete={onDelete}
          onEdit={onEdit}
          key={modifier.id}
          isDropdownOutOfEdge={isDropdownOutOfEdge}
        />
      ))}
    </div>
  );
};

export default ModifierGroupsCards;
