import NoItems from '../../../../utilities/no-items/no-items';
import BackdropLoading from '../../../../utilities/backdrop-loading/backdrop-loading';

import { IoCubeOutline } from 'react-icons/io5';

const CategoryCardsSection = ({
  isLoading = false,
  length = 0,
  noItemsProps: { title, description } = {},
  icon: Icon = IoCubeOutline,
  children,
}) => {
  if (length > 0 && isLoading) {
    return (
      <>
        {children}
        <BackdropLoading />
      </>
    );
  }

  if (length < 1 && isLoading) {
    return <BackdropLoading />;
  }

  if (length < 1 && !isLoading) {
    return (
      <div>
        <NoItems title={title} description={description} icon={Icon} />
      </div>
    );
  }

  return children;
};

export default CategoryCardsSection;
