import Spinner from '../spinner/spinner';

import clsx from 'clsx';

import styles from './no-items.module.scss';

function NoItems({ title = '', description = '', icon: Icon = null, isLoading = false, className }) {
  return (
    <div className={clsx(styles.no_items, className)}>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <Icon />
          <p>{title}</p>
          <p>{description}</p>
        </>
      )}
    </div>
  );
}

export default NoItems;
