import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { useAppContext } from '../../../../../context/context';

import { fetchMenuItem, createMenuItem, updateExistingMenuItem } from '../../../../../redux/menuItemsSlice';
import { addNotification } from '../../../../../redux/tooltipSlice';
import { fetchCategories } from '../../../../../redux/categoriesSlice';
import { fetchTaxes } from '../../../../../redux/taxesSlice';
import { fetchModifiers } from '../../../../../redux/modifierSetsSlice';

import PageTitle from '../../../../utilities/page-title/page-title';
import Input from '../../../../utilities/input/input';
import Textarea from '../../../../utilities/textarea/textarea';
import TagSelector from '../../../../utilities/tag-selector/tag-selector';
import Button from '../../../../utilities/button/button';
import { convertValueByType } from '../../../../utilities/utils';

import AddNewTaxModal from '../modals/add-new-tax/add-new-tax';

import { FaPlus } from 'react-icons/fa';
import clsx from 'clsx';
import styles from './add-or-edit-item.module.scss';

const AddOrEditItem = () => {
  // Hooks
  const { token } = useAppContext();
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Determination if the page is in edit mode
  const isEditMode = Boolean(id);

  // Redux Store
  const menu_item = useSelector((state) => state.menu_items.menu_item);
  const categories = useSelector((state) => state.categories.categories);
  const taxes = useSelector((state) => state.taxes.taxes);
  const modifierGroups = useSelector((state) => state.modifierSets.modifierSets);

  const taxesLoading = useSelector((state) => state.taxes.status);
  const categoriesLoading = useSelector((state) => state.categories.status);
  const modifierGroupsLoading = useSelector((state) => state.modifierSets.status);

  const taxesStatusLoading = taxesLoading === 'loading';
  const categoriesStatusLoading = categoriesLoading === 'loading';
  const modifiersStatusLoading = modifierGroupsLoading === 'loading';

  const isTotalLoading = taxesStatusLoading || categoriesStatusLoading || modifiersStatusLoading;

  // Local state
  const [formValues, setFormValues] = useState({
    // Values of form inputs
    name: '',
    price: '',
    desc: '',
    category_ids: [],
    tax_ids: [],
    modifier_group_ids: [],
  });

  const [transformedTaxes, setTransformedTaxes] = useState([]); // Transformed taxes
  const [transformedCategories, setTransformedCategories] = useState([]); // Transformed categories
  const [transformedModifiers, setTransformedModifiers] = useState([]); // Transformed modifiers

  const [itemImageContent, setItemImageContent] = useState(''); // logo icon content
  const NoImageStub = require('../../../../../assets/icons/image_stub.png');

  const [isCreateNewTaxModalOpen, setIsCreateNewTaxModalOpen] = useState(false); // State for Add new tax modal

  // Effects
  useEffect(() => {
    if (isEditMode) {
      dispatch(fetchMenuItem({ id, token })); // Fetch the item if not already in the Redux store
    } else {
      setFormValues({
        name: '',
        price: '',
        desc: '',
        category_ids: [],
        tax_ids: [],
        modifier_group_ids: [],
      });
    }
  }, [dispatch, id, isEditMode, token]);

  useEffect(() => {
    if (menu_item && isEditMode) {
      setFormValues({
        name: menu_item.name || '',
        price: menu_item.price || '',
        desc: menu_item.desc || '',
        category_ids: menu_item.category_ids || [],
        tax_ids: menu_item.tax_ids || [],
        modifier_group_ids: menu_item.modifier_group_ids || [],
      });
    } else {
      setFormValues({
        name: '',
        price: '',
        desc: '',
        category_ids: [],
        tax_ids: [],
        modifier_group_ids: [],
      });
    }
  }, [menu_item, isEditMode]);

  useEffect(() => {
    if (token) {
      dispatch(fetchCategories({ token }));
      dispatch(fetchTaxes(token));
      dispatch(fetchModifiers({ token }));
    }
  }, [dispatch, token]);

  useEffect(() => {
    if (taxes && taxes.length > 0) {
      const result = taxes.map((item) => ({ value: item.id, label: item.name }));
      setTransformedTaxes(result);
    }

    if (categories && categories.length > 0) {
      const result = categories.map((item) => ({ value: item.id, label: item.name }));
      setTransformedCategories(result);
    }

    if (modifierGroups && modifierGroups.length > 0) {
      const result = modifierGroups.map((item) => ({ value: item.id, label: item.name }));
      setTransformedModifiers(result);
    }
  }, [taxes, categories, modifierGroups]);

  // Handlers
  const handleLogoFileChange = (event) => {
    // Handler add logo image
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        const base64String = reader.result;

        setItemImageContent(base64String);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleLogoClick = () => {
    document.getElementById('logoInput').click();
  };

  const handleAction = (message, status) => {
    dispatch(addNotification({ message, status }));
  };

  const handleInputChange = (e, type) => {
    const { name, value } = e.target;

    if (type === 'number') {
      const result = value.replace(/[^0-9.]/g, '');

      if (result.split('.').length > 2) return;
      setFormValues((prev) => ({
        ...prev,
        [name]: result,
      }));
    } else {
      setFormValues((prev) => ({
        ...prev,
        [name]: convertValueByType(value, type),
      }));
    }
  };

  const handleSaveChanges = async (e) => {
    e.preventDefault();

    if (isEditMode) {
      // Updating existing item
      const editedItem = {
        ...menu_item,
        ...formValues,
        image: itemImageContent || null,
      };

      try {
        await dispatch(updateExistingMenuItem({ id: Number(id), updatedDetails: editedItem, token })).unwrap();
        navigate('/menu/items');
      } catch {
        handleAction('Failed to edit Item', 'failed');
      }
    } else {
      // Adding new item
      const createdItem = {
        ...formValues,
        active: true,
        image: itemImageContent || null,
      };

      try {
        await dispatch(createMenuItem({ newDetails: createdItem, token })).unwrap();
        navigate('/menu/items');
      } catch {
        handleAction('Failed to create new Item', 'failed');
      }
    }
  };

  const handleCancel = () => {
    navigate('/menu/items');
  };

  return (
    <section className={styles.add_or_edit_item_page}>
      <div className={styles.header}>
        <PageTitle name={isEditMode ? 'Item details' : 'New Item'} />
      </div>

      <div className={styles.content}>
        <div className={styles.subtitle}>Create a New Item. You can edit it later.</div>
        <div className={styles.settings}>
          <div className={styles.inputs_form}>
            <div className={styles.field}>
              <Input
                label={'Item name'}
                type="text"
                value={formValues.name}
                onChange={(e) => handleInputChange(e, 'string')}
                placeholder={'Item Name'}
                required={true}
                name={'name'}
                className={styles.name_input}
              />
            </div>

            <div className={styles.field}>
              <Input
                label={'Price'}
                value={String(formValues.price)}
                onChange={(e) => handleInputChange(e, 'number')}
                placeholder={'$ 00.00'}
                required={true}
                name={'price'}
                step="0.01"
                className={styles.price_input}
              />
            </div>

            <div className={clsx(styles.field, styles.full_width)}>
              <Textarea
                label="Description / Ingredients"
                value={formValues.desc}
                placeholder={'Description / Ingredients'}
                onChange={(e) => handleInputChange(e, 'string')}
                name={'desc'}
                className={styles.description}
              />
            </div>

            <div className={styles.field}>
              <TagSelector
                label="Tax Category"
                placeholder="Select a Tax Category"
                items={transformedTaxes}
                isLoading={isTotalLoading}
                selectedItemsIds={formValues?.tax_ids}
                onChange={(items) => handleInputChange({ target: { name: 'tax_ids', value: items } })}
                className={styles.tax_selector}
                isActionButtons={false}
                customButton={{
                  title: 'Add Tax Category',
                  onClick: () => setIsCreateNewTaxModalOpen(true),
                  icon: <FaPlus />,
                }}
              />
            </div>

            <div className={styles.field}>
              <TagSelector
                label="Sales Category"
                placeholder="Select a Sales Category"
                items={transformedCategories}
                isLoading={isTotalLoading}
                selectedItemsIds={formValues?.category_ids}
                onChange={(items) => handleInputChange({ target: { name: 'category_ids', value: items } })}
                className={styles.category_selector}
              />
            </div>

            <div className={styles.field}>
              <TagSelector
                label="Modifier Groups"
                placeholder="Select Modifier Groups"
                items={transformedModifiers}
                isLoading={isTotalLoading}
                selectedItemsIds={formValues?.modifier_group_ids}
                onChange={(items) => handleInputChange({ target: { name: 'modifier_group_ids', value: items } })}
                className={styles.modifier_groups_selector}
              />
            </div>
          </div>

          <div className={styles.item_image}>
            <span className={styles.requirements}>Requirements: up to 10Mb for JPG, PNG, and SVG</span>
            <div className={styles.image_content}>
              <div className={styles.image_wrapper} onClick={handleLogoClick}>
                {itemImageContent ? (
                  <img src={itemImageContent} alt={`Item ${formValues.name}`} className={styles.image} />
                ) : (
                  <div className={styles.no_image_stub}>
                    <img src={NoImageStub} alt="Click to upload" className={styles.no_image_icon} />
                    <span className={styles.stub_description}>Click to upload image</span>
                  </div>
                )}
              </div>
              <input
                type="file"
                id="logoInput"
                style={{ display: 'none' }}
                accept="image/*"
                onChange={handleLogoFileChange}
              />
            </div>
          </div>
        </div>

        <div className={styles.buttons}>
          {isEditMode ? (
            <>
              <Button
                className={styles.button}
                variant={'secondary'}
                disabled={isTotalLoading}
                title={'Close'}
                onClick={handleCancel}
              />
              <Button
                className={styles.button}
                disabled={isTotalLoading}
                title={'Edit Item'}
                onClick={handleSaveChanges}
              />
            </>
          ) : (
            <>
              <Button
                className={styles.button}
                variant={'secondary'}
                disabled={isTotalLoading}
                title={'Cancel'}
                onClick={handleCancel}
              />
              <Button
                className={styles.button}
                disabled={isTotalLoading}
                title={'Add Item'}
                onClick={handleSaveChanges}
              />
            </>
          )}
        </div>
      </div>

      {isCreateNewTaxModalOpen && (
        <AddNewTaxModal isModalOpen={isCreateNewTaxModalOpen} closeModal={() => setIsCreateNewTaxModalOpen(false)} />
      )}
    </section>
  );
};

export default AddOrEditItem;
