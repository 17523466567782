import clsx from 'clsx';
import VenueLogo from '../../../../assets/img/venue_logo_example.jpg';
import QR from '../../../../assets/img/venue_qr_example.png';

import styles from './customer-receipt-settings.module.scss';

const CustomerReceiptExample = ({ settings, bussinessInfo }) => {
  return (
    <section className={styles.receipt_example}>
      <div className={styles.example_content}>
        <div className={styles.header}>
          {settings.show_order_id && <p className={styles.order_id}>Order ID: ORD564454</p>}
          <div className={styles.venue_customer}>
            {settings.show_venue_logo && (
              <img className={styles.venue_logo} src={VenueLogo} alt={bussinessInfo?.name || 'Venue Logo'} />
            )}
            <div className={styles.venue_name}>{bussinessInfo?.name}</div>
            {settings.show_venue_address && <p className={styles.venue_address}>{bussinessInfo?.address_string}</p>}
            {settings.show_venue_phone && <p className={styles.venue_phone}>{bussinessInfo.phone_number}</p>}
            {settings.show_website_address && <p className={styles.venue_website}>{bussinessInfo.website}</p>}
            {settings.show_order_type && <h3 className={styles.title}>Open Order</h3>}
          </div>
          <ul className={styles.information}>
            <li>Receipt ID: REC87564454</li>
            {settings.show_employee && <li>Employee: David Miller</li>}
            {settings.show_order_time && <li>Order Time: Apr-29-2021 07:15 PM</li>}
            {settings.show_print_time && <li>Order Time: Apr-29-2021 07:15 PM</li>}
          </ul>
        </div>

        <div className={styles.dish_section}>
          <div className={styles.dish_details}>
            <p className={clsx(styles.line, styles.price)}>
              1 x Chicken Meals<span>$9.99</span>
            </p>
            {settings.show_modifiers && (
              <ul className={styles.additional_info_list}>
                <li className={styles.price}>
                  Extra spices<span>$1.99</span>
                </li>
                <li className={styles.price}>
                  Extra spices<span>$1.99</span>
                </li>
                <li className={styles.price}>
                  Extra spices<span>$1.99</span>
                </li>
              </ul>
            )}
            <p className={clsx(styles.line, styles.price)}>
              Sub Total<span>$9.99</span>
            </p>
            {settings.show_refund_amount && (
              <p className={clsx(styles.line, styles.price)}>
                Refund Amound<span>$2.99</span>
              </p>
            )}
            <p className={clsx(styles.line, styles.price)}>
              Service Charge<span>$9.99</span>
            </p>
            {settings.show_split_amount && (
              <p className={clsx(styles.line, styles.price)}>
                Non-Cash Adjustments/Surcharge<span>$9.99</span>
              </p>
            )}
            <p className={clsx(styles.line, styles.price)}>
              Total Discount<span>$9.99</span>
            </p>
            <p className={clsx(styles.line, styles.price)}>
              Non-Cash Adjustments<span>$9.99</span>
            </p>
            <p className={clsx(styles.line, styles.price)}>
              Total Price<span>$9.99</span>
            </p>
            <p className={clsx(styles.line, styles.price)}>
              Charge Amount<span>$9.99</span>
            </p>
          </div>
        </div>
        <div>
          {settings.show_tip_suggestion && (
            <>
              <p className={clsx(styles.line, styles.underline)}>Additional Tips</p>
              <p className={clsx(styles.line, styles.price, styles.underline)}>
                Entertainment($4.00%)<span>$9.99</span>
              </p>
            </>
          )}
          {settings.show_tip_line_for_cash && (
            <p className={clsx(styles.line, styles.price)}>
              Tips<span>$9.99</span>
            </p>
          )}
          <p className={clsx(styles.line, styles.price)}>
            Total<span>$9.99</span>
          </p>
          <p className={clsx(styles.line, styles.price)}>
            Transaction ID<span>$9.99</span>
          </p>
          <p className={clsx(styles.line, styles.price)}>
            Transaction Type<span>$9.99</span>
          </p>
        </div>
        {!settings.show_customer_name && !settings.show_customer_phone && !settings.show_customer_address ? null : (
          <div className={styles.customer_section}>
            <h4 className={styles.section_title}>Customer Details</h4>
            <address className={styles.customer_info}>
              {settings.show_customer_name && <p className={styles.customer_name}>David Miller</p>}
              {settings.show_customer_phone && <p className={styles.customer_phone}>(635) 987-3354</p>}
              {settings.show_customer_address && (
                <p className={styles.customer_address}>77450 DW 51 FH AT, Suite 503</p>
              )}
            </address>
          </div>
        )}
        {settings.show_order_note && (
          <div className={styles.order_note}>
            <h4 className={styles.note_title}>Order Note</h4>
            <p className={styles.note_text}>Sugar Free, No Herbs & Spices</p>
          </div>
        )}
        {settings.show_qr_code && <img className={styles.qr_code} src={QR} alt="Example Venue QR" />}
      </div>
    </section>
  );
};

export default CustomerReceiptExample;
