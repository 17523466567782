import React, { useState, useEffect } from 'react';

import clsx from 'clsx';

import { convertValueByType } from '../../../../utilities/utils';
import Input from '../../../../utilities/input/input';
import DynamicFormFields from '../../../../utilities/dynamic-form-fields/dynamic-form-fields ';
import useDebounce from '../../../../utilities/useDebounce';

import { FaPlus } from 'react-icons/fa';

import styles from './modifier-group-form.module.scss';

const ModifierFormSection = ({ data, onChange, isSubModifier = false, errors }) => {
  const [modifier, setSubModifier] = useState(data);

  const debouncedAddress = useDebounce(modifier, 200);

  const labelAndPlaceholder = `${isSubModifier ? 'Submodifier' : 'Modifier'} Name`;

  const handleInputChange = (e, type) => {
    const { name, value } = e.target;
    setSubModifier((prev) => ({
      ...prev,
      [name]: convertValueByType(value, type),
    }));
  };

  useEffect(() => {
    onChange(modifier);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedAddress]);

  return (
    <div className={clsx({ [styles.sub_modifier]: isSubModifier }, styles.modifier_form)}>
      <div className={styles.form_container}>
        <div className={clsx(styles.field, styles.name)}>
          <Input
            type="text"
            name="name"
            label={labelAndPlaceholder}
            placeholder={labelAndPlaceholder}
            value={modifier?.name}
            onChange={handleInputChange}
          />
        </div>
        {!isSubModifier && (
          <>
            <div className={clsx(styles.field, styles.min_max)}>
              <Input
                type="number"
                name="min"
                label="min"
                min={modifier?.sub_modifiers?.length > 0 ? 1 : 0}
                value={(modifier?.min ?? modifier?.sub_modifiers?.length > 0) ? 1 : 0}
                onChange={handleInputChange}
              />
            </div>
            <div className={clsx(styles.field, styles.min_max)}>
              <Input
                type="number"
                name="max"
                label="max"
                min={1}
                value={modifier?.max ?? 1}
                onChange={handleInputChange}
              />
            </div>
          </>
        )}
        {(isSubModifier || modifier?.sub_modifiers?.length < 1) && (
          <div className={clsx(styles.field, styles.price)}>
            <Input
              type="number"
              name="price"
              placeholder="00.00"
              label="Price"
              value={modifier?.price}
              onChange={handleInputChange}
              step="0.01"
              prefixIcon="$"
            />
          </div>
        )}
      </div>
      {!isSubModifier && (
        <DynamicFormFields
          value={modifier?.sub_modifiers}
          fieldItem={ModifierFormSection}
          addButtonTitle={'Add Submodifier'}
          onChange={(value) => handleInputChange({ target: { name: 'sub_modifiers', value } })}
          containerClass={clsx(styles.dynamic_form_container, styles.sub_modifier)}
          fieldProps={{
            isSubModifier: true,
          }}
          formClasses={{
            buttonClassName: clsx(styles.delete_button, styles.sub_modifier),
            constainerClassName: clsx(styles.dynamic_row_container, styles.sub_modifier),
          }}
          addButtonClasses={{
            prefixIcon: <FaPlus />,
            className: clsx(styles.add_dynamic_field_button, styles.sub_modifier),
            variant: 'transparent',
          }}

          // errors={errors?.modifiers}
        />
      )}
    </div>
  );
};

export default ModifierFormSection;
